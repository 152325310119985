<template>
  <div class="d-flex w-100 align-center">
    <div class="icon-container mr-2">
      <v-card
        width="64"
        height="64"
        min-width="64"
        min-height="64"
        outlined
        elevation="0"
        class="rounded-lg"
      >
        <v-icon
          x-large
          color="primary"
          @click="openAddressSelector"
          class="h-100 w-100"
          >${{ $t(`navbar.service.${getDeliveryServiceId}.fontIcon`) }}</v-icon
        >
      </v-card>
    </div>
    <div class="info-container w-100 d-flex justify-space-between">
      <div class="mx-2 d-flex flex-column justify-start w-100">
        <div class="info-text d-flex" @click="openAddressSelector">
          <!-- <span class="font-weight-medium mr-1">{{ serviceName }} </span> -->
          <span class="w-100 d-flex justify-space-between">
            {{ shippingAddress }}
            <v-icon
              small
              right
              color="primary"
              v-if="$route.name != 'Payment' && cart.cartStatusId != 7"
            >
              $edit
            </v-icon>
          </span>
        </div>
        <div
          class="info-text d-flex"
          :disabled="!editableTimeslot"
          @click="openTimeslotSelector"
        >
          <!-- <span class="font-weight-medium mr-1"
            >{{ $t("cart.cartInfo.timeslot") }}
          </span> -->
          <span class="w-100 d-flex justify-space-between">
            {{ timeslot }}
            <v-icon small right color="primary" v-if="$route.name != 'Payment'">
              $edit
            </v-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.info-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  .font-weight-medium {
    font-weight: 600 !important;
  }
}
.right-border {
  border-right: 1px solid $secondary;
}
</style>
<script>
import { mapState } from "vuex";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
export default {
  name: "MiniCartInfoList",
  mixins: [login, cartInfo],
  data() {
    return {
      maxWidth: 320,
      maxHeight: 60
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  }
};
</script>
