global.config = {
  google_api_key: process.env.VUE_APP_GOOGLE_API_KEY,
  googleGeocodeParams: {
    components: "country:it|administrative_area:Lombardia"
  },
  baseUrl: "",
  proximityDefaultDistance: "10000.0",
  showSectors: false,
  hashNotReload: ["/cart/", "/payment"],
  filterEmptyCategory: true,
  dayWordsSaved: 30,
  version: process.env.VUE_APP_VERSION,
  minProfileLevel: 2,
  registration: true,
  checkVersionInterval: 1000 * 60 * 10, //ogni 10 minuti
  reloadAfterMillis: 1000 * 60 * 30, //dopo 30 minuti di inattività restarta la app
  domainWhitelist: ["iperdrive.digitelematica.org", "localhost"],
  showInPopupCode: [
    "msg.cart.availability.errors",
    "error.registration.fiscal_code.not_valid",
    "error.registration.need_password_reset",
    "error.checkout.missing_billing_data",
    "error.registration.registration_not_confirmed",
    "error.registration.registration_not_confirmed_pwd",
    "error.icsmallcart.error.product.not.available",
    "info.cart.changetimeslot.recalculated",
    "info.order.reopen"
  ],
  dialogs: [],
  pushNotificationEnabled: true,
  // urlScheme: "ebsnali://",
  deeplinks: {
    "/profile/orders": "/profile/orders",
    "/profile/orders/:orderId": "/profile/orders/:orderId",
    "/checkout": "/checkout",
    "/search": "/search",
    "/shop/product/:slug": "/product/:slug",
    "/shop/category/:slug": "/:slug",
    "/product/:slug": "/product/:slug",
    "/category/*": "/category/*",
    "/page/:slug": "/page/:slug",
    "/registration/recover-password-confirm": "/recover-password-confirm",
    "/registration/reset-password-request": "/reset-password-request",
    "/registration/reset-password-sent": "/reset-password-sent",
    "/registration/reset-password": "/reset-password",
    "/registration/reset-password-completed": "/reset-password-completed",
    "/registration/confirm": "/registration/confirm",
    "/registration/confirm/success": "/registration/confirm/success",
    "/registration/confirm/already-confirmed":
      "/registration/confirm/already-confirmed",
    "/registration/confirm/*": "/registration/confirm/error",
    "/recover-password-confirm": "/recover-password-confirm",
    "/reset-password-request": "/reset-password-request",
    "/reset-password-sent": "/reset-password-sent",
    "/reset-password": "/reset-password",
    "/reset-password-completed": "/reset-password-completed"
  },
  // filterProductClasses: [1, 2, 3, 4, 6],
  updateCart: true,
  dialogMaxWidth: 800,
  addToFavoritesWidth: 350,
  analyticsClass: "analyticsServiceGTM",
  cartItemInfos: ["size", "customWeight", "use_wpid"],
  acceptAlternatives: true,
  categoryTreeDepth: 3,
  // facebookClientId: "1250974205313975",
  // googleClientId:
  //   "1061314893204-tbicvnnsq74cjiarigmq3jiilqu56b0v.apps.googleusercontent.com",
  defaultPaymentTypeId: 12,
  socialLogo: "/logo/social.png",
  defaultStoreName: "Catalogo Globale",
  categoryPrefix: "",
  defaultPageName: "IperDrive",
  recaptchaTokenEnabled: false,
  recaptchaEnabledEndpoints: [
    "/ebsn/api/registration/update_user_detail",
    "/ebsn/api/auth/login",
    "/ebsn/api/registration-card/password_reset",
    "/ebsn/api/registration-card/register-user",
    "/ebsn/api/registration-card/update_password"
  ],
  filterVouchersByDate: true,
  everlyZoneId: 1000002,
  customCountries: [
    {
      countryId: 1,
      iso: "IT",
      name: "Italia"
    },
    {
      countryId: 2,
      iso: "MT",
      name: "Malta"
    },
    {
      countryId: 3,
      iso: "DE",
      name: "Germania"
    },
    /*{
      countryId: 4,
      iso: "RO",
      name: "Romania"
    },*/
    {
      countryId: 5,
      iso: "FR",
      name: "Francia"
    }
  ]
};
