<template>
  <div class="product-price d-flex justify-center ml-2 mr-auto">
    <span class="max-purchasable smaller" v-if="product.productInfos.MAXACQ">
      max {{ product.productInfos.MAXACQ }}
    </span>
    <div
      v-if="
        checkout &&
          product?.warehousePromo?.view?.MPieces &&
          product?.warehousePromo?.view?.bubbleCheckout
      "
      v-html="product?.warehousePromo?.view?.bubbleCheckout"
    ></div>
    <div v-else>
      <div class="old-price" v-if="showStandardPrice">
        {{
          $n(
            $options.confezione(
              product.productInfos.TIPOLOGIA,
              product.priceStandardDisplay,
              product.productInfos.WEIGHT_SELLING
            ),
            "currency"
          )
        }}
        <span
          v-if="
            product.productInfos.TIPOLOGIA == 'Sfuso' ||
              product.productInfos.TIPOLOGIA == 'Confezione'
          "
          class="unit-price"
          >/{{ product.weightUnitDisplay }}
        </span>
      </div>
    </div>
    <div
      class="d-flex flex-column justify-space-between align-end price-container mt-auto"
      v-if="
        product.warehousePromo &&
          product.warehousePromo.warehousePromoTypeId == 1
      "
    >
      <!-- <div>
        <span class="mxn-label">
          {{ product.warehousePromo.view.MPieces }} pezzi
          {{
            $n(
              product.warehousePromo.view.MPieces *
                product.priceDisplay,
              "currency"
            )
          }}
        </span>
      </div> -->
      <div class="cur-price w-100">
        {{
          $n(
            $options.confezione(
              product.productInfos.TIPOLOGIA,
              showStandardDisplay
                ? product.priceStandardDisplay
                : product.priceDisplay,
              product.productInfos.WEIGHT_SELLING
            ),
            "currency"
          )
        }}
        <span
          v-if="
            product.productInfos.TIPOLOGIA == 'Sfuso' ||
              product.productInfos.TIPOLOGIA == 'Confezione'
          "
          class="unit-price"
          >/{{ product.weightUnitDisplay }}
        </span>
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-space-between align-end price-container mt-auto"
    >
      <div class="cur-price">
        {{
          $n(
            $options.confezione(
              product.productInfos.TIPOLOGIA,
              showStandardDisplay
                ? product.priceStandardDisplay
                : product.priceDisplay,
              product.productInfos.WEIGHT_SELLING
            ),
            "currency"
          )
        }}
        <span
          v-if="
            product.productInfos.TIPOLOGIA == 'Sfuso' ||
              product.productInfos.TIPOLOGIA == 'Confezione'
          "
          class="unit-price"
          >/{{ product.weightUnitDisplay }}
        </span>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.product-price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: fit-content;

  .card {
    position: absolute;
    bottom: 86px;
    left: 120px;
    img {
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.85);
      width: 46px;
      margin-bottom: 3px;
    }
  }

  .max-purchasable {
    display: none;
  }
  .mxn-label {
    font-size: 11px;
    // color: $primary;
  }
  .cur-price {
    text-align: left;
    line-height: 22px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #3d3d3d;
    white-space: nowrap;
    .unit-price {
      font-size: 1rem;
      text-transform: lowercase;
      position: relative;
      left: -5px;
    }
  }
  .old-price {
    font-size: 12px;
    min-height: 23px;
    font-weight: normal;
    letter-spacing: 0px;
    text-decoration: line-through;
    white-space: nowrap;
    padding: 4px 3px 0 3px;
    .unit-price {
      font-size: 10px;
      text-transform: lowercase;
    }
  }
}
.is-cordova {
  .price-wraper {
    justify-content: flex-start !important;
  }
  .product-price {
    width: 100% !important;
  }
  .product-card {
    .iva {
      margin-bottom: 2px;
    }
  }
}
.checkout {
  .cart-item .has-promo {
    .product-price {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        position: absolute;
        // bottom: 38px;
        left: 2px;
      }
    }
  }
  .accept-alternatives {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin-top: 8px !important;
    }
  }
}
.horizontal-crm .product_promo .bubble {
  top: 0px;
  left: -68px;
  width: 65px;
  right: initial;
}
</style>
<script>
// import toNumber from "lodash/toNumber";
export default {
  name: "ProductPrice",
  props: {
    product: { type: Object, required: true },
    showStandardPrice: { type: Boolean, default: false },
    checkout: { type: Boolean, default: false },
    showStandardDisplay: { type: Boolean, default: false }
  },
  // eslint-disable-next-line no-unused-vars
  confezione(tiplogia, price, weightUnit) {
    // if (tiplogia == "Confezione") {
    //   return (price * 1000) / (1000 / toNumber(weightUnit, 1)) / 1000;
    // } else {
    //   return price;
    // }
    return price;
  }
};
</script>
