<template>
  <div class="homepage-widget" v-if="isAuthenticated">
    <!-- <v-row>
      <v-col>
        <h2 class="font-weight-semibold">
          {{ $t("widgets.homepageWidget.title", [cart.user.firstName]) }}
        </h2>
      </v-col>
    </v-row> -->
    <v-row class="mt-n2 mb-2">
      <v-col cols="12" md="6" class="pr-4" :style="colStyle">
        <CartInfoCompact :isCart="true" class="infos" />
      </v-col>
      <v-col cols="12" md="6" class="pl-2">
        <div class="d-flex w-100 align-center">
          <div class="icon-container mr-4">
            <v-card
              width="64"
              height="64"
              min-width="64"
              min-height="64"
              outlined
              elevation="0"
              class="rounded-lg"
              :to="{ name: 'Lists' }"
            >
              <v-icon size="30" color="primary" class="h-100 w-100"
                >$starLight</v-icon
              >
            </v-card>
          </div>
          <div class="info-container w-100 d-flex justify-space-between">
            <router-link :to="{ name: 'Lists' }">
              <div
                class="text-decoration-underline default--text font-weight-semibold"
              >
                {{ $t("widgets.homepageWidget.wishlist") }}
                <span class="ml-1"
                  ><v-icon size="12" color="default"
                    >$chevronRight</v-icon
                  ></span
                >
              </div>
            </router-link>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss">
.homepage-widget {
  .icon-container {
    min-width: max-content;
  }
}
</style>
<script>
import CartInfoCompact from "@/components/cart/CartInfoCompact.vue";

import cartInfo from "~/mixins/cartInfo";
import login from "~/mixins/login";
import { mapState } from "vuex";
export default {
  name: "HomepageWidget",
  components: {
    CartInfoCompact
  },
  mixins: [cartInfo, login],
  data() {
    return {
      serviceImage: null
    };
  },
  props: {
    homeComponent: { type: Boolean, default: true }
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    colStyle() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return "border-right: 1px solid var(--v-grey-lighten1)";
      }
      return null;
    },
    serviceName() {
      return this.$t(
        "navbar.decodeServiceName." +
          this.cart.shippingAddress.deliveryServiceId
      );
    }
  },
  methods: {
    async openTimeSlotSelector() {
      await this.setTimeslot();
    },
    setServiceImage(serviceId) {
      if (!serviceId) serviceId = this.cart.shippingAddress.deliveryServiceId;
      if (serviceId === 1) this.serviceImage = "clicca-e-ritira-neg";
      if (serviceId === 2) this.serviceImage = "consegna-a-casa-neg";
      if (serviceId === 4) this.serviceImage = "locker-bianco";
    },
    async handleEditTimeslot() {
      await this.openTimeslotSelector();
      global.EventBus.$emit("timeslotchanged");
    }
  },
  created() {
    if (this.isAuthenticated) {
      this.setServiceImage();
    }
  },
  watch: {
    "cart.shippingAddress.deliveryServiceId": function(val) {
      this.setServiceImage(val);
    }
  }
};
</script>
