var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"privacy-tab"},[_c('v-form',{ref:"form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_vm._l((_vm.disclaimerList),function(disclaimer,index){return _c('div',{key:disclaimer.registrationDisclaimerId},[_c('p',{staticClass:"mb-0 text-h4"},[_vm._v(_vm._s(disclaimer.disclaimerTitle))]),(disclaimer.priority == 2)?_c('div',{staticClass:"privacy-title"},[_vm._v(" "+_vm._s(_vm.$t("profile.privacyProfiling.title"))+" ")]):_vm._e(),(disclaimer.priority == 3)?_c('div',[_vm._v(" "+_vm._s(_vm.$t(`profile.privacyMarketing.title.${_vm.storeId}`))+" ")]):_vm._e(),(disclaimer.disclaimerUrl)?_c('div',{staticClass:"py-3"},[_c('a',{attrs:{"aria-label":disclaimer.disclaimerContent,"title":disclaimer.disclaimerContent,"href":disclaimer.disclaimerUrl,"target":"_blank"}},[_vm._v(_vm._s(disclaimer.disclaimerContent))])]):(disclaimer.disclaimerHtml)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.openDisclaimerHtmlPopup(disclaimer.disclaimerHtml)}}},[_vm._v("Scopri di più")]):_vm._e(),(disclaimer.required == 'true')?_c('div',[_c('v-checkbox',{staticClass:"mt-0 font-weight-semibold",attrs:{"rules":_vm.isCheckboxTrue,"true-value":"1","false-value":"0","label":"Ho letto*"},model:{value:(disclaimer.value),callback:function ($$v) {_vm.$set(disclaimer, "value", $$v)},expression:"disclaimer.value"}})],1):_c('div',[_c('v-radio-group',{attrs:{"row":!_vm.$vuetify.breakpoint.xsOnly,"rules":[_vm.requiredRules()]},model:{value:(disclaimer.value),callback:function ($$v) {_vm.$set(disclaimer, "value", $$v)},expression:"disclaimer.value"}},[_c('v-radio',{staticClass:"font-weight-semibold",attrs:{"label":"Do il consenso","value":"1","color":"default"},on:{"click":function($event){return _vm.setMarketingValue(
                index == _vm.disclaimerList.length - 1,
                disclaimer
              )}}}),_c('v-radio',{staticClass:"font-weight-semibold",attrs:{"label":"Nego il consenso","value":"0","color":"default"},on:{"click":function($event){return _vm.setMarketingValue(
                index == _vm.disclaimerList.length - 1,
                disclaimer
              )}}})],1),(_vm.showMarketingWarning && index == _vm.disclaimerList.length - 1)?_c('v-alert',{staticClass:"marketing-warning",attrs:{"type":"warning"}},[_c('span',{staticClass:"d-block",domProps:{"innerHTML":_vm._s(_vm.$t('register.marketinConfirmPopup.content'))}}),_c('span',{staticClass:"d-block",domProps:{"innerHTML":_vm._s(_vm.$t('register.marketinConfirmPopup.content2'))}})]):_vm._e()],1)],1)}),(_vm.showUpdateButton)?_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","depressed":"","large":""},on:{"click":_vm.update}},[_vm._v(" "+_vm._s(_vm.$t("profile.privacyUpdate"))+" ")])],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }