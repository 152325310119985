<template>
  <div class="address-selector fill-height">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />

    <v-layout fill-height column>
      <div class="d-none d-sm-flex justify-center">
        <img class="logo" :src="serviceImg" height="60" alt="Logo IperDrive" />
      </div>
      <h3 class="mb-sm-0 text-center" v-if="category">
        {{ category.name }}
      </h3>
      <v-row
        v-if="deliveryServices.length"
        no-gutters
        justify="space-between"
        align="center"
        class="my-1 my-sm-3 tabs"
      >
        <v-col
          cols="12"
          sm="6"
          :md="deliveryServiceButtonsLength"
          v-for="(service, idx) in deliveryServices"
          :key="service.deliveryServiceId"
          @click="fetchAddresses(service.deliveryServiceId, idx)"
        >
          <div
            class="tab d-flex flex-column flex-md-column align-center justify-center flex-grow-1 pa-1 mx-1 mb-1 mb-md-0"
            :class="
              service.deliveryServiceId === selectedDeliveryServiceId
                ? `${service.cssClass} selected`
                : `${service.cssClass}`
            "
          >
            <v-icon
              :color="
                service.deliveryServiceId === selectedDeliveryServiceId
                  ? 'white'
                  : 'primary'
              "
              large
              class="delivery-service-icon mx-3 mx-md-0"
              size="80"
              >{{
                "$" + $t(`navbar.service.${service.deliveryServiceId}.fontIcon`)
              }}</v-icon
            >
            <div
              class="d-flex flex-column align-start align-md-center justify-start flex-grow-1"
            >
              <h4>{{ service.name }}</h4>
              <div class="service-descr text-left text-md-center">
                {{ service.descr }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <p
        class="selector-subitle mt-2 mb-4 text-center"
        v-html="category.description"
        v-if="category"
      ></p>
      <v-card :loading="loading" flat>
        <v-row
          no-gutters
          class="justify-center px-1"
          v-if="selectedDeliveryServiceId != 2"
        >
          <!-- filtered search drive and locker -->
          <v-col :cols="12" :sm="12" class="d-flex address-selector-auto-cnt">
            <vue-google-autocomplete
              v-if="googleEnabled"
              id="pac-input"
              classname="google-autocomplete-input address-to-search w-100"
              ref="addresstosearchref"
              :placeholder="
                $t(
                  'navbar.service.' + selectedDeliveryServiceId + '.searchLabel'
                )
              "
              :types="'geocode'"
              v-on:placechanged="addressToSearchChanged"
              :enable-geolocation="true"
              country="it"
              @keyup.enter.native="searchFirst"
            >
            </vue-google-autocomplete>
            <v-btn
              class="search-autocomplete-btn"
              height="40"
              depressed
              @click="searchFirst"
            >
              <v-icon>$search</v-icon>
            </v-btn>
          </v-col>
          <!-- <v-col cols="12" sm="4" class="d-flex justify-center ">
            <v-btn
              class="geolocate-btn primary--text text--lighten-1 mt-3 mt-sm-0"
              color="primary lighten-1"
              height="40"
              outlined
              depressed
              @click="geolocate()"
            >
              <v-icon small>$placeholder</v-icon>
              <span class="primary--text text--lighten-1">
                {{ $t("addresses.geolocateButton") }}
              </span>
            </v-btn>
          </v-col> -->
        </v-row>
        <div class="w-100 address-list pt-3">
          <v-row v-if="!isAuthenticated && selectedDeliveryServiceId === 2">
            <v-col cols="12" md="6">
              <v-text-field
                background-color="white"
                class="rounded-pill"
                :label="$t('profile.addresses.city')"
                v-model="cityFilter"
                hide-details
                outlined
                filled
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                background-color="white"
                class="rounded-pill"
                :label="$t('profile.addresses.postalCode')"
                v-model="postalCodeFilter"
                hide-details
                outlined
                filled
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-radio-group
            v-model="selectedShippingAddressId"
            v-if="filteredAddresses && filteredAddresses.length > 0"
          >
            <v-radio
              v-for="address in filteredAddresses"
              :key="address.addressId"
              @click="setAddress(address)"
              :value="address.addressId"
              color="default"
              class="pa-3"
              active-class="primary lighten-2"
            >
              <template v-slot:label>
                <div
                  class="address-selector-label w-100 d-flex flex-row align-center"
                >
                  <div class="address-selector-content">
                    <div class="address-name w-100">
                      {{ address.addressName }}
                    </div>
                    <!-- <div class="address-name font-weight-semibold w-100">
                      {{ address.addressName }} - {{ address.addressId }} -{{
                        user?.defaultStoreAddress.addressId
                      }}
                    </div>
                    <div class="text-body-2">
                      {{
                        $t(
                          "navbar.address." + address.addressTypeId + ".list",
                          address
                        )
                      }}
                    </div> -->
                  </div>
                  <div
                    class="adddress-selector-icons ml-auto"
                    v-if="
                      address.addressId === user?.defaultStoreAddress.addressId
                    "
                  >
                    <v-icon>$starLight</v-icon>
                  </div>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
          <v-alert
            type="warning"
            v-else-if="!loading && selectedDeliveryServiceId == 1"
          >
            {{ $t("addressSelector.noPdvFound") }}
          </v-alert>
          <div class="d-flex justify-end">
            <v-btn
              v-if="isHomeDelivery && isAuthenticated"
              class="add-new-addr-btn rounded-pill"
              large
              depressed
              :to="{ name: 'EditAddress' }"
              @click="$emit('submit', false)"
              ><v-icon left>
                $plus
              </v-icon>
              {{ $t("addresses.addAddressButton") }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-layout>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.add-new-addr-btn {
  border: 1px solid var(--v-grey-lighten1) !important;
  background-color: white !important;
}
.delivery-service-icon {
  font-size: 100px !important;
}
.address-selector {
  h3 {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      line-height: 2;
      font-size: 16px;
    }
  }
  .tab {
    border: 1px solid var(--v-grey-lighten1);
    transition: border-color 0.3s ease;
    border-radius: 8px;
    background-color: #ffffff;
    min-height: 110px;
    cursor: pointer;
    &.selected {
      background-color: var(--v-primary-base);
      border-color: var(--v-primary-base);
      color: $white !important;
      .v-icon {
        color: $white !important;
      }
    }
    h5 {
      font-weight: 600;
      margin: 0;
    }
    .service-descr {
      font-size: 12px;
      display: none;
    }
  }
  .tab:hover {
    border-color: var(--v-primary-base);
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    .tab {
      min-height: 70px;
    }
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .tab {
      min-height: 58px;
    }
  }
  .address-list {
    overflow-y: auto;
    min-height: 200px;
    max-height: calc(100vh - 505px);
    padding: 2px;
    .v-label {
      flex-direction: column;
      align-items: start;
    }
    .v-btn {
      border: none;
    }
    .v-radio {
      border-bottom: 1px solid var(--v-grey-lighten1);
      margin-bottom: 0;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      min-height: 200px;
      max-height: calc(70vh - 200px);
      height: auto;
    }
  }

  .address-selector-auto-cnt {
    background: var(--v-grey-lighten2);
    border-radius: 6px;
    .address-to-search {
      padding: 0px 12px;
    }
  }
  .search-autocomplete-btn {
    background-color: var(--v-grey-lighten2) !important;
    margin-left: 4px;
  }
}
</style>
<script>
import cartInfo from "~/mixins/cartInfo";
import categoryMixin from "~/mixins/category";
import VueGoogleAutocomplete from "vue-google-autocomplete";

import StoreService from "~/service/storeService";
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";

import gmapsInit from "@/plugins/gmapsinit";

import { mapState, mapGetters } from "vuex";

export default {
  name: "AddressSelector",
  mixins: [cartInfo, categoryMixin],
  components: {
    VueGoogleAutocomplete
  },
  data() {
    return {
      store: {},
      addresses: [],
      distanceRange: 25000,
      loading: null,
      selectedShippingAddressId: null,
      selectedDeliveryServiceId: null,
      tab: 2,
      googleEnabled: false,
      searchText: null,
      category: {},
      showAddAddress: false,
      cityFilter: null,
      postalCodeFilter: null
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      isAuthenticated: "cart/isAuthenticated"
    }),
    filteredAddressesV2() {
      return this.filteredAddresses.filter(address => {
        const cityMatch = this.cityFilter
          ? address.city.toLowerCase().includes(this.cityFilter.toLowerCase())
          : true;
        const postalCodeMatch = this.postalCodeFilter
          ? address.postalcode.includes(this.postalCodeFilter)
          : true;
        return cityMatch && postalCodeMatch;
      });
    },
    deliveryServiceButtonsLength() {
      return 12 / this.deliveryServices.length;
    },
    filteredAddresses() {
      if (this.searchText) {
        return this.addresses.filter(address => {
          return (
            address.addressName
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.address1
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.city.toLowerCase().indexOf(this.searchText.toLowerCase()) >
              -1
          );
        });
      } else {
        return this.addresses;
      }
    },
    ...mapState({
      cart: ({ cart }) => cart.cart,
      selectedService: ({ cart }) => cart.selectedService
    }),
    deliveryServices() {
      if (this.store && this.store.deliveryServices) {
        return this.store.deliveryServices.filter(button => {
          return button.showTab;
        });
      }
      return [];
    },
    isHomeDelivery() {
      let homeIds = [2, 6, 10];
      return homeIds.includes(this.selectedDeliveryServiceId);
    }
  },
  methods: {
    // geolocate() {
    //   let vm = this;
    //   if (navigator.geolocation) {
    //     document.getElementById("pac-input").value = "";
    //     document.getElementById("pac-input").placeholder =
    //       "Localizzazione in corso...";
    //     navigator.geolocation.getCurrentPosition(
    //       function(position) {
    //         var latlng = new vm.google.maps.LatLng(
    //           position.coords.latitude,
    //           position.coords.longitude
    //         );
    //         let addressData = null;
    //         var geocoder = new vm.google.maps.Geocoder();
    //         geocoder.geocode({ location: latlng }, function(results, status) {
    //           if (status == vm.google.maps.GeocoderStatus.OK) {
    //             addressData = results[0];
    //             vm.addressToSearch = {
    //               latitude: addressData.geometry.location.lat(),
    //               longitude: addressData.geometry.location.lng()
    //             };
    //             document.getElementById("pac-input").placeholder = vm.$t(
    //               "navbar.service." +
    //                 vm.selectedDeliveryServiceId +
    //                 ".searchLabel"
    //             );
    //             document.getElementById("pac-input").value =
    //               addressData.formatted_address;
    //             vm.searchNearWarehouse();
    //           } else {
    //             console.log(
    //               "Geocode was not successful for the following reason: "
    //             );
    //           }
    //         });
    //       },
    //       function(err) {
    //         console.log(err);
    //         alert(
    //           "è necessario abilitare la localizzazione per utilizzare questa funzione"
    //         );
    //       },
    //       { enableHighAccuracy: false, maximumAge: Infinity }
    //     );
    //   }
    // },
    searchFirst() {
      // try get first suggestin google autocomplete
      let vm = this;
      let pacItem = document.getElementsByClassName("pac-item");
      if (pacItem.length > 0) {
        let addressData = null;
        let firstResult = document.getElementsByClassName("pac-item")[0]
          .innerText;
        let geocoder = new vm.google.maps.Geocoder();
        geocoder.geocode({ address: firstResult }, function(results, status) {
          if (status == vm.google.maps.GeocoderStatus.OK) {
            addressData = results[0];
            vm.addressToSearch = {
              latitude: addressData.geometry.location.lat(),
              longitude: addressData.geometry.location.lng()
            };
            // update search field text and start store locator search
            document.getElementById("pac-input").value =
              addressData.formatted_address;
            vm.searchNearWarehouse();
          } else {
            vm.addressToSearch = null;
          }
        });
      }
    },
    searchNearWarehouse() {
      this.fetchAddresses();
    },
    distanceRangeChanged() {
      if (this.addressToSearch) {
        this.searchNearWarehouse();
      }
    },
    addressToSearchChanged(address) {
      this.addressToSearch = {
        latitude: address.latitude,
        longitude: address.longitude
      };

      this.searchNearWarehouse();
    },
    async remove(address) {
      let _this = this;
      const res = await _this.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        let response = await DeliveryService.removeAddress(address.addressId);
        _this.addresses = response.addresses;
      }
    },
    edit(address) {
      let _this = this;
      _this.$router.push({
        path: "/profile/addresses/manage/" + address.addressId
      });
      _this.$emit("submit", true);
    },
    async fetchStore() {
      let _this = this;
      let store = await StoreService.getStoreById(
        _this?.cart?.store?.storeId || 5
      );
      _this.store = store;

      _this.selectedDeliveryServiceId = 1;
      if (this.isAuthenticated) {
        _this.store.deliveryServices.find(deliveryService => {
          if (
            deliveryService.deliveryServiceId ==
            _this.cart.shippingAddress.deliveryServiceId
          ) {
            _this.selectedDeliveryServiceId = deliveryService.deliveryServiceId;
          }
        });
      }
    },
    async fetchAddresses(deliveryServiceId = 1) {
      let _this = this;
      _this.loading = true;
      _this.searchText = null;
      _this.selectedDeliveryServiceId = deliveryServiceId;

      let result;
      if (this.addressToSearch) {
        result = await DeliveryService.getAddressByDeliveryService(
          _this.selectedDeliveryServiceId,
          this.addressToSearch.latitude,
          this.addressToSearch.longitude,
          this.distanceRange
        );
      } else {
        result = await DeliveryService.getAddressByDeliveryService(
          _this.selectedDeliveryServiceId
        );
      }
      if (result.addresses) {
        _this.addresses = result.addresses;
      }
      if (
        this.isAuthenticated &&
        !this.cart.shippingAddress.autoAssigned &&
        this.cart.shippingAddress.deliveryServiceId == deliveryServiceId
      ) {
        this.selectedShippingAddressId = this.cart.shippingAddress.addressId;
      }
      _this.loading = false;
    },
    async setAddress(address) {
      let _this = this;
      this.loading = true;
      let data = await this.$store.dispatch("cart/setAddress", {
        addressType: address.addressType,
        addressId: address.addressId
      });

      this.loading = false;
      if (data) {
        _this.$emit("submit", true);
        setTimeout(function() {
          _this.$router.push({ name: "Home" });
        }, 200);
      }
    },

    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug("service");
      } catch (e) {
        console.log(e);
      }
    },
    resetFields() {
      this.showAddAddress = !this.showAddAddress;
    }
    // async openStoreSelector() {
    //   this.$emit("submit", "setStore");
    // }
  },
  async created() {
    let vm = this;
    try {
      vm.google = await gmapsInit(global.config.google_api_key);
      if (vm.google) {
        vm.googleEnabled = true;
      }
    } catch (err) {
      console.log(err);
    }
    this.fetchCategory();
    this.fetchStore();
    this.fetchAddresses(this?.cart?.shippingAddress?.deliveryServiceId);
    if (this.isAuthenticated && !this.cart.shippingAddress.autoAssigned) {
      this.selectedShippingAddressId = this.cart.shippingAddress.addressId;
    }
  }
};
</script>
