<template>
  <div class="privacy-tab">
    <v-form ref="form" v-model="isFormValid">
      <div
        v-for="(disclaimer, index) in disclaimerList"
        :key="disclaimer.registrationDisclaimerId"
      >
        <p class="mb-0 text-h4">{{ disclaimer.disclaimerTitle }}</p>
        <div v-if="disclaimer.priority == 2" class="privacy-title">
          {{ $t("profile.privacyProfiling.title") }}
        </div>
        <div v-if="disclaimer.priority == 3">
          {{ $t(`profile.privacyMarketing.title.${storeId}`) }}
        </div>
        <div v-if="disclaimer.disclaimerUrl" class="py-3">
          <a
            :aria-label="disclaimer.disclaimerContent"
            :title="disclaimer.disclaimerContent"
            :href="disclaimer.disclaimerUrl"
            target="_blank"
            >{{ disclaimer.disclaimerContent }}</a
          >
        </div>
        <!-- <div
          v-else
          v-html="disclaimer.disclaimerHtml"
          class="privacy-text"
        ></div> -->
        <v-btn
          color="primary"
          text
          v-else-if="disclaimer.disclaimerHtml"
          @click="openDisclaimerHtmlPopup(disclaimer.disclaimerHtml)"
          >Scopri di più</v-btn
        >
        <div v-if="disclaimer.required == 'true'">
          <v-checkbox
            v-model="disclaimer.value"
            :rules="isCheckboxTrue"
            true-value="1"
            false-value="0"
            label="Ho letto*"
            class="mt-0 font-weight-semibold"
          ></v-checkbox>
        </div>
        <div v-else>
          <v-radio-group
            v-model="disclaimer.value"
            :row="!$vuetify.breakpoint.xsOnly"
            :rules="[requiredRules()]"
          >
            <v-radio
              label="Do il consenso"
              value="1"
              class="font-weight-semibold"
              color="default"
              @click="
                setMarketingValue(
                  index == disclaimerList.length - 1,
                  disclaimer
                )
              "
            ></v-radio>
            <v-radio
              label="Nego il consenso"
              value="0"
              class="font-weight-semibold"
              color="default"
              @click="
                setMarketingValue(
                  index == disclaimerList.length - 1,
                  disclaimer
                )
              "
            ></v-radio>
            <!-- @click="openConfirmPopup(index == disclaimerList.length - 1, disclaimer)" -->
          </v-radio-group>
          <v-alert
            type="warning"
            v-if="showMarketingWarning && index == disclaimerList.length - 1"
            class="marketing-warning"
          >
            <span
              class="d-block"
              v-html="$t('register.marketinConfirmPopup.content')"
            ></span>
            <span
              class="d-block"
              v-html="$t('register.marketinConfirmPopup.content2')"
            ></span>
          </v-alert>
        </div>
      </div>
      <div class="d-flex justify-end" v-if="showUpdateButton">
        <v-btn color="primary" depressed @click="update" large>
          {{ $t("profile.privacyUpdate") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<style lang="scss">
.privacy-tab {
  .privacy-text {
    margin-top: 8px;
    border: 1px solid var(--v-grey-base);
    border-radius: 4px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 5px;
    margin-bottom: 5px;
    max-height: 150px;
    font-size: 11px;
  }
  .privacy-title {
    font-size: 12px;
  }
}
</style>

<script>
import CustomConfirmDialog from "@/components/CustomConfirmDialog.vue";

import CustomService from "@/service/customService";
import UserService from "@/commons/service/userService";

import { requiredValue } from "~/validator/validationRules";
import { getPersonInfo, setPersonInfo } from "~/service/ebsn";
import { isCheckboxTrue } from "@/customValidationRules";

export default {
  data() {
    return {
      disclaimerList: [],
      isFormValid: false,
      requiredRules: requiredValue,
      isCheckboxTrue: [requiredValue(), isCheckboxTrue()],
      showMarketingWarning: false
    };
  },
  props: {
    storeId: { type: Number, required: true },
    userData: { type: Object, required: true },
    showUpdateButton: { type: Boolean, default: true }
  },
  methods: {
    setMarketingValue(isMarketing, disclaimer) {
      if (isMarketing) {
        if (disclaimer.value === "0") {
          this.showMarketingWarning = true;
        } else {
          this.showMarketingWarning = false;
        }
      }
    },
    async openConfirmPopup(mustOpen = false, disclaimer) {
      if (mustOpen) {
        let res = await this.$dialog.show(CustomConfirmDialog, {
          waitForResult: true,
          path: "register.marketinConfirmPopup",
          dialogClass: "no-marketing-popup"
        });
        if (!res) {
          disclaimer.value = true;
        }
      }
    },
    openDisclaimerHtmlPopup(disclaimerHtml) {
      global.vm.$dialog.confirm({
        text: disclaimerHtml,
        actions: {
          true: {
            color: "red",
            text: global.EventBus.$t("profile.privacyProfiling.close")
          }
        },
        width: 700
      });
    },
    async fetchDisclaimerListt() {
      let _this = this;
      await CustomService.getDisclaimerList(2, _this.storeId).then(data => {
        _this.disclaimerList = data.registrationDisclaimers;
        _this.disclaimerList.forEach(d => {
          d.value = getPersonInfo(_this.userData.person, d.personInfoTypeId);
        });
      });
    },
    async update() {
      if (this.$refs.form.validate()) {
        await this.applyChages();
        UserService.updateUserDetail(this.userData).then(() => {
          this.$emit("userDataUpdated", this.storeId);
        });
      }
    },
    async applyChages() {
      this.disclaimerList.forEach(disclaimer => {
        setPersonInfo(
          this.userData.person,
          disclaimer.personInfoTypeId,
          disclaimer.value || "0"
        );
      });
    }
  },
  async mounted() {
    await this.fetchDisclaimerListt();
  },
  watch: {
    storeId() {
      this.fetchDisclaimerListt();
    }
  }
};
</script>
