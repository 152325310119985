import router from "@/router/index.js";
import axios from "~/plugins/axios";
export default {
  provinceList(countryId, homeDeliveryOnly) {
    return axios
      .get("/ebsn/api/geo/province-list", {
        params: {
          country_id: countryId,
          home_delivery_only: homeDeliveryOnly
        }
      })
      .then(data => {
        return data.data.data.results;
      });
  },
  getCountries(continentId, homeDeliveryOnly) {
    return axios
      .get("/ebsn/api/geo/country-list", {
        params: {
          continent_id: continentId,
          home_delivery_only: homeDeliveryOnly
        }
      })
      .then(data => {
        return data.data.data.results;
      });
  },

  getPaymentDueDateList() {
    return axios
      .get(`/ebsn/api/payment-due-date/list?show_all=true`)
      .then(data => {
        return data.data.data;
      });
  },
  getTransactions() {
    return axios.get(`/ebsn/api/payment-due-date/transactions`).then(data => {
      return data.data.data;
    });
  },
  registerUser(user, registrationCardAction) {
    let url = `/ebsn/api/registration-card/register-user?registration_card_action=${registrationCardAction}`;
    return axios
      .post(url, user)
      .then(response => response.data)
      .catch(err => err);
  },
  verifyEmail(params) {
    let registrationCardAction = params.action;
    let url = `/ebsn/api/registration-card/registration_confirm?action=${registrationCardAction}&email=${params.email}&coopId=${params.coopId}&token=${params.token}`;
    const registrationConfirmInterceptor = axios.interceptors.response.use(
      function(res) {
        if (
          res.request.responseURL.indexOf("/registration/confirm/success") > -1
        ) {
          axios.interceptors.response.eject(registrationConfirmInterceptor);
          router.push("/registration/confirm/success");
        } else if (
          res.request.responseURL.indexOf("/registration/confirm/error") > -1
        ) {
          axios.interceptors.response.eject(registrationConfirmInterceptor);
          router.push("/registration/confirm/error");
        } else if (
          res.request.responseURL.indexOf(
            " /registration/confirm/already-confirmed"
          ) > -1
        ) {
          axios.interceptors.response.eject(registrationConfirmInterceptor);
          router.push("/registration/confirm/already-confirmed");
        } else {
          axios.interceptors.response.eject(registrationConfirmInterceptor);
          return res;
        }
      },
      function(error) {
        return Promise.reject(error);
      }
    );
    return axios
      .post(url)
      .then(response => response.data)
      .catch(err => err);
  },
  changeDateOfOrder(orderid, neworderdate) {
    // let url = `/ebsn/api/order/change-date?order_id=${orderid}&new_order_date=${neworderdate}`;
    let url = `/ebsn/api/order/change-date`;
    var params = {
      order_id: orderid,
      new_order_date: neworderdate
    };
    return axios
      .post(url, params)
      .then(response => response.data.data)
      .catch(err => err);
  },
  getDisclaimerList(registrationModuleTypeId, storeId) {
    let url = `/ebsn/api/ccno-registration-disclaimer/list`;
    var params = {
      registration_module_type_id: registrationModuleTypeId
        ? registrationModuleTypeId
        : 2,
      store_id: storeId
    };
    return axios.get(url, { params }).then(data => {
      if (data && data.data) {
        return data.data.data;
      } else {
        return null;
      }
    });
  },
  updateEmailRequest(user) {
    let url = "/ebsn/api/registration-card/update_email_request";
    return axios
      .post(url, user)
      .then(response => response.data)
      .catch(err => err);
  },
  updatePassword(newPassword) {
    let url = "/ebsn/api/registration-card/update_password";
    return axios
      .post(url, { new_password: newPassword })
      .then(response => response.data)
      .catch(err => err);
  },
  resetPassword(email, token, password) {
    let url = "/ebsn/api/registration-card/password_reset";
    return axios
      .post(url, { e_mail: email, reset_token: token, password: password })
      .then(response => response.data)
      .catch(err => err);
  },
  getCards() {
    return axios.get("/ebsn/api/registration-card/view-cards").then(data => {
      if (data && data.data) {
        return data.data.data;
      } else {
        return null;
      }
    });
  },
  checkCard(fidelityCard, birthDate) {
    return axios
      .get(
        `/ebsn/api/registration-card/check-card?birth_date=${birthDate}&fidelity_card=${fidelityCard}`
      )
      .then(data => {
        if (data) {
          return data.data;
        } else {
          return null;
        }
      });
  },
  addCard(fidelityCard, birthDate) {
    return axios
      .post(
        `/ebsn/api/registration-card/add-card?birth_date=${birthDate}&fidelity_card=${fidelityCard}`
      )
      .then(data => {
        if (data && data.data) {
          return data.data;
        } else {
          return null;
        }
      });
  },
  removeCard(fidelityCard) {
    return axios
      .post(
        `/ebsn/api/registration-card/remove-card?fidelity_card=${fidelityCard}`
      )
      .then(data => {
        if (data && data.data) {
          return data.data;
        } else {
          return null;
        }
      });
  },
  getBenefits() {
    return axios
      .get(`/ebsn/api/cart-giftcertificate/cart-threshold`)
      .then(data => {
        if (data) {
          return data.data.data;
        } else {
          return null;
        }
      });
  },
  polverizePoints(points) {
    return axios
      .post(`/ebsn/api/registration-card/convert_points_polv?points=${points}`)
      .then(data => {
        if (data && data.data) {
          return data.data;
        } else {
          return null;
        }
      });
  },
  onLoginHandler(uid, sig, sigTS) {
    //TODO: replace with EBSN URL
    var params = {
      xUID: uid,
      xUIDSignature: sig,
      xsignatureTimestamp: sigTS
    };
    return axios
      .post("/ebsn/api/finiper/verify-login", params)
      .then(data => {
        if (data.error_code == 0) {
          window.location.reload();
        } else {
          alert("AP" + data.error_code + ":" + data.error_description);
        }
      })
      .error(function(p1, p2) {
        console.log(p1);
        console.log(p2);
        alert("AJ001:Impossibile verificare il login");
      });
  },
  getAccountInfoResponse(uid, pwd) {
    //TODO: replace with EBSN URL
    return axios
      .post(
        "/ebsn/api/finiper/verify-login?username=" + uid + "password=" + pwd
      )
      .then(data => {
        if (data.error_code == 0) {
          window.location.reload();
        }
      });
  },
  registerWithFidelity(fidelity, lastName) {
    let params = {
      fidelityCard: fidelity,
      lastName: lastName
    };
    return axios
      .post("/ebsn/api/finiper/registration-with-card-load", params)
      .then(data => {
        if (data) {
          return data.data;
        } else {
          return null;
        }
      })
      .catch(err => err);
  },
  anagDataGet(client_code, identifier, person_type_id) {
    let url = `/ebsn/api/finiper-anagraphic/anag_data_get`;
    return axios
      .post(url, {
        client_code,
        identifier,
        person_type_id
      })
      .then(response => response.data)
      .catch(err => err);
  },
  anagDataSave(payload) {
    let url = `/ebsn/api/finiper-anagraphic/anag_data_save`;
    return axios
      .post(url, payload)
      .then(response => response.data)
      .catch(err => err);
  },
  getDigitalReceipt(orderId) {
    return axios
      .get(`/ebsn/api/finiper-digireceipt/getReceiptHtml?order_id=${orderId}`)
      .then(response => {
        if (response) {
          return response.data;
        } else {
          return null;
        }
      });
  },
  associateCard(user) {
    return axios
      .post(`/ebsn/api/finiper/associate_card`, user)
      .then(response => {
        if (response) {
          return response.data;
        } else {
          return null;
        }
      });
  },
  customCheckCode(code) {
    let url = `/ebsn/api/finiper-cart-giftcertificate/check?code=${code}`;
    return axios
      .post(url)
      .then(response => response.data)
      .catch(err => err);
  },
  customAddCode(code) {
    let url = `/ebsn/api/finiper-cart-giftcertificate/add_code?code=${code}`;
    return axios
      .post(url)
      .then(response => response.data)
      .catch(err => err);
  },
  customRemoveCode(code) {
    let url = `/ebsn/api/finiper-cart-giftcertificate/remove_code?code=${code}`;
    return axios
      .post(url)
      .then(response => response.data)
      .catch(err => err);
  },
  fetchStoryblokPostById(id) {
    return axios
      .get(
        `${process.env.VUE_APP_STORYBLOK_API_BASEURL}/cdn/stories/${id}?token=${process.env.VUE_APP_STORYBLOK_API_KEY}`
      )
      .then(response => {
        if (response) {
          return response.data;
        } else {
          return null;
        }
      });
  }
};
