var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(_vm.handleView),expression:"handleView",modifiers:{"once":true}}],staticClass:"proposal-card-overlay",style:(_vm.cardStyle),attrs:{"href":_vm.link},on:{"click":function($event){$event.preventDefault();return _vm.handleLink.apply(null, arguments)}}},[_c('v-img',{attrs:{"src":_vm.imgSrc,"alt":_vm.proposal.imgAlt,"title":_vm.proposal.imgDescription,"eager":""}},[(
        _vm.$ebsn.meta(
          _vm.proposal,
          'metaData.category_proposal_type.SHOW_BLACK_LAYER'
        )
      )?_c('div',{staticClass:"blurred-bg"}):_vm._e(),_c('div',{staticClass:"text-overlay",class:[
        _vm.$ebsn.meta(
          _vm.proposal,
          'metaData.category_proposal_type.TEXT_ALIGN',
          ' text-align-left '
        ),
        _vm.$ebsn.meta(
          _vm.proposal,
          'metaData.category_proposal_type.TEXT_ALIGN_VERTICAL',
          ' text-align-vertical-bottom '
        )
      ]},[_c('div',{staticClass:"text-overlay-content"},[(_vm.proposal.descr)?_c('v-card-title',{domProps:{"innerHTML":_vm._s(_vm.proposal.descr)}}):_vm._e(),(_vm.proposal.content)?_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.proposal.content)}}):_vm._e(),(
            _vm.$ebsn.meta(_vm.proposal, 'metaData.category_proposal_type.BTN_TEXT')
          )?_c('v-card-actions',[_c('v-btn',{attrs:{"href":_vm.link,"outlined":_vm.$ebsn.meta(
                _vm.proposal,
                'metaData.category_proposal_type.BTN_OUTLINED',
                false
              ),"color":_vm.$ebsn.meta(
                _vm.proposal,
                'metaData.category_proposal_type.BTN_COLOR'
              )}},[_vm._v(_vm._s(_vm.$ebsn.meta(_vm.proposal, "metaData.category_proposal_type.BTN_TEXT")))])],1):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }