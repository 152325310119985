const UPDATE_MENU_POSITION_MODEL = "updateMenuPositionModel";
const SET_APPLICATION_CONFIG = "setApplicationConfig";

const state = {
  menuPositionModel: 0,
  applicationConfig: {}
};
const getters = {
  getMenuPositionModel: state => {
    return state.menuPositionModel || 0;
  },
  getApplicationConfig: state => {
    return state.applicationConfig;
  }
};

const actions = {
  [UPDATE_MENU_POSITION_MODEL]({ commit }, value) {
    commit(UPDATE_MENU_POSITION_MODEL, value);
  },
  [SET_APPLICATION_CONFIG]({ commit }, value) {
    commit(SET_APPLICATION_CONFIG, value);
  }
};
const mutations = {
  [UPDATE_MENU_POSITION_MODEL]: (state, value) => {
    state.menuPositionModel = value;
  },
  [SET_APPLICATION_CONFIG]: (state, value) => {
    state.applicationConfig = value;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
