<template>
  <div
    class="cart-info-card-wrapper d-flex d-md-none align-center grey lighten-1 rounded-md py-3"
    v-if="isAuthenticated"
  >
    <v-btn
      :aria-label="serviceName"
      text
      @click="openAddressSelector"
      class="service-button"
      height="50"
    >
      <v-icon large class="service-icon">
        {{ "$" + $t(`navbar.service.${getDeliveryServiceId}.fontIcon`) }}
      </v-icon>
    </v-btn>

    <div
      class="cart-info-address-card-wrapped nav-selectors d-flex flex-column"
    >
      <v-btn
        x-small
        text
        class="delivery-btn d-flex justify-space-between"
        @click="openAddressSelector"
      >
        <span class="info-text font-weight-semibold">{{ shippingAddress }}</span
        ><v-icon color="default" x-small class="ml-2">$edit</v-icon>
      </v-btn>
      <v-btn
        x-small
        text
        class="delivery-btn d-flex justify-space-between"
        @click="openTimeslotSelector"
      >
        <span class="info-text font-weight-regular text-lowercase">
          {{ timeslot }} </span
        ><v-icon color="default" x-small class="ml-2">$edit</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<style lang="scss">
.cart-info-card-wrapper {
  .nav-selectors,
  .delivery-btn .v-btn__content {
    width: 100%;
    overflow: hidden;
  }
  .info-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
<script>
import cartInfo from "~/mixins/cartInfo";
import login from "~/mixins/login";

export default {
  name: "CartInfoCardWrapper",
  mixins: [cartInfo, login],
  methods: {}
};
</script>
