var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.products.length > 0)?_c('div',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(_vm.handleView),expression:"handleView",modifiers:{"once":true}}],staticClass:"product-list-slider slider product-slider",class:_vm.classes,style:(_vm.backgroundImage),attrs:{"id":`product-list-slider-${_vm.config.categoryBlockId}`},on:{"mouseover":function($event){_vm.animateLink = true},"mouseleave":function($event){_vm.animateLink = false}}},[(
      _vm.$ebsn.meta(_vm.config, 'categoryblocktype_' + _vm.categoryBlockName + '.TITLE')
    )?_c('TitleRow',{attrs:{"config":_vm.config,"categoryBlockName":_vm.categoryBlockName,"showMore":_vm.showMore,"animateLink":_vm.animateLink}}):_vm._e(),_c('ebsn-meta',{class:'title-row mb-3',attrs:{"target":_vm.config,"path":`categoryblocktype_${_vm.name}.DESCRIPTION`,"tag":"div"}}),_c('div',{staticClass:"slider product-slider"},[_c('swiper',{ref:"swiperRef",attrs:{"options":_vm.swiperOption}},[(_vm.showCard)?_c('swiper-slide',[_c('product-slider-card',{attrs:{"alt":"","img":_vm.$ebsn.meta(
              _vm.config,
              'categoryblocktype_' + _vm.name + '.CARD_IMAGE.location'
            ),"text":_vm.$ebsn.meta(_vm.config, 'categoryblocktype_' + _vm.name + '.CARD_TEXT'),"link":{
            name: 'Category',
            params: {
              pathMatch: _vm.$ebsn.meta(
                this.config,
                'categoryblocktype_' + _vm.name + '.CATEGORY.slug'
              )
            }
          },"linkLabel":_vm.$ebsn.meta(_vm.config, 'categoryblocktype_' + _vm.name + '.BTN_TEXT')}})],1):_vm._e(),_vm._l((_vm.products),function(product,index){return _c('swiper-slide',{key:product.productId},[_c('ProductCard',{key:product.productId,attrs:{"product":product,"position":_vm.initPosition + index},on:{"selectProduct":function($event){return _vm.$emit('selectProduct', {
              product: product,
              index: _vm.initPosition + index
            })}}})],1)})],2),_c('swiper-paginator',{attrs:{"showBullets":_vm.$ebsn.meta(
          _vm.config,
          'categoryblocktype_' + _vm.name + '.SHOW_BULLETS',
          true
        ),"showArrows":_vm.$ebsn.meta(
          _vm.config,
          'categoryblocktype_' + _vm.name + '.SHOW_ARROWS',
          false
        ),"paginationClass":_vm.config.categoryBlockId,"length":_vm.products.length}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }