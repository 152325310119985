function passwordRule(error) {
  var errorMessage = error
    ? error
    : "La password deve essere di 8-25 caratteri e contenere almeno 1 carattere minuscolo, 1 carattere maiuscolo, 1 numero ed 1 carattere speciale";
  return function(v) {
    return (
      !v ||
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+*!=]).{8,25}$/.test(v) ||
      errorMessage
    );
  };
}

function isFidelityCard(error) {
  var errorMessage = error
    ? error
    : "Non è un numero di IperDrive Card valido.";
  return function(v) {
    return !v || /^[0-9]{13}$/.test(v) || errorMessage;
  };
}

function isLotteryCode(error) {
  var errorMessage = error ? error : "Il codice deve essere di otto caratteri.";
  return function(v) {
    return !v || /^[a-zA-Z0-9]{8}$/.test(v) || errorMessage;
  };
}

function isPiva(error) {
  var errorMessage = error ? error : "Formato partita IVA non corretto";
  return function(v) {
    return !v || /^[0-9]{11,12}$/.test(v) || errorMessage;
  };
}

function isFiscalCode(error) {
  var errorMessage = error ? error : "Codice fiscale non valido";
  return function(v) {
    return (
      !v ||
      /^(((?:[B-DF-HJ-NP-TV-Z](?:[AEIOU]{2}|[AEIOU]X)|[AEIOU]{2}X|[AEIOU]{3}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[\dLMNP-V][1-9MNP-V]|[1-9MNP-V][0L]))[A-Z])|([0-9]{11}))$/.test(
        v.toUpperCase()
      ) ||
      errorMessage
    );
  };
}

function isStrictFiscalCode(error) {
  var errorMessage = error ? error : "Codice fiscale non valido";
  return function(v) {
    return (
      !v ||
      /^(((?:[B-DF-HJ-NP-TV-Z](?:[AEIOU]{2}|[AEIOU]X)|[AEIOU]{2}X|[AEIOU]{3}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[\dLMNP-V][1-9MNP-V]|[1-9MNP-V][0L]))[A-Z]))$/.test(
        v.toUpperCase()
      ) ||
      errorMessage
    );
  };
}

function isCheckboxTrueBool(error) {
  var errorMessage = error ? error : "Campo obbligatorio";
  return function(v) {
    if (!v) {
      return errorMessage;
    } else {
      return true;
    }
  };
}

function isCheckboxTrue(error) {
  var errorMessage = error ? error : "Campo obbligatorio";
  return function(v) {
    if (v && v !== "1") {
      return errorMessage;
    } else {
      return true;
    }
  };
}

function isSDICode(error) {
  var errorMessage = error ? error : "Codice non valido";
  return function(v) {
    return !v || /^[a-zA-Z0-9]{7}$/.test(v) || errorMessage;
  };
}

export {
  passwordRule,
  isFidelityCard,
  isLotteryCode,
  isPiva,
  isFiscalCode,
  isCheckboxTrueBool,
  isCheckboxTrue,
  isStrictFiscalCode,
  isSDICode
};
