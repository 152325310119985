<template>
  <div
    class="d-flex align-center not-available-product product-lock-cutoff-container mr-2"
  >
    <v-tooltip top v-for="(hour, index) in hourLocks" :key="index">
      <template v-if="hour.isOppositeHours" v-slot:activator="{ on, attrs }">
        <div
          class="hour-lock available-range"
          dark
          v-bind="attrs"
          v-on="on"
          :hour="hour"
          @click.prevent=""
          @mousedown.stop
        >
          <v-icon class="lock-cutoff-icon" color="primary">$clock</v-icon>
          <!-- <span v-html="hour.shortText"></span> -->
        </div>
      </template>
      <template v-else v-slot:activator="{ on, attrs }">
        <div class="hour-lock" dark v-bind="attrs" v-on="on" :hour="hour">
          <v-icon class="lock-cutoff-icon" color="primary">$clock</v-icon>
          <!-- <span v-html="hour.shortText"></span> -->
        </div>
      </template>
      <div class="product-lock-tooltip-description">
        <span v-html="hour.longText"></span>
      </div>
    </v-tooltip>
  </div>
</template>
<style lang="scss">
.product-lock-tooltip-description {
  max-width: 400px;
}
.product-lock-cutoff-container {
  .lock-cutoff-icon {
    font-size: 28px;
  }
}
</style>
<script>
import split from "lodash/split";
export default {
  name: "ProductLockCutoff",
  props: ["hours"],
  computed: {
    hourLocks() {
      let array = split(this.hours, " ");
      //array[0] = "10:00-12:00";

      // patch for the new version
      if (this.hours.indexOf(",") > -1) {
        array = split(this.hours, ",");
      }

      let result = [];
      for (let i = 0; i < 1; i++) {
        result[i] = {};
        let splittedHours = array[i].split("-");
        let dateStart = this.$dayjs(splittedHours[0], "HH:mm");
        let dateEnd = this.$dayjs(splittedHours[1], "HH:mm");
        if (dateStart.isAfter(dateEnd)) {
          result[i].isOppositeHours = true;
          // result[i].shortText = splittedHours[1] + "-" + splittedHours[0];
          // let value = this.$dayjs().format("D/MM/YYYY");
          result[i].longText = this.$t("lockCutoff.availableRange", [
            splittedHours[1],
            splittedHours[0]
          ]);
        } else {
          result[i].isOppositeHours = false;
          // let value = this.dayjs().format("D/MM/YYYY");
          // result[i].shortText = array[i];
          result[i].longText = this.$t("lockCutoff.notAvailableRange", [
            splittedHours[1],
            splittedHours[0]
          ]);
        }
      }
      return result;
    }
  }
};
</script>
