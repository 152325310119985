<template>
  <div class="timeslot-selector pa-4">
    <div class="d-flex justify-center">
      <img class="logo" :src="serviceImg" height="60" alt="Logo IperDrive" />
    </div>
    <ebsn-meta
      class="text-center"
      :target="category"
      path="category_info.TITLE"
      :default-value="category.name"
      tag="h3"
    ></ebsn-meta>
    <ebsn-meta
      :target="category"
      path="category_info.DESCRIPTION"
      class="text-center text-body-2 mt-2"
    ></ebsn-meta>
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
      :container="false"
    />
    <h5 class="text-uppercase font-weight-semibold">
      {{ $t("timeslot.daysTitle") }}
    </h5>
    <v-tabs
      hide-slider
      v-model="tab"
      center-active
      :height="110"
      class="days-tabs mb-6"
    >
      <v-tab
        v-for="timeslotDay in days"
        :key="timeslotDay.dateIso"
        class="days-tab rounded-lg mr-3 w-100"
        @click="selectTimeslotDay(timeslotDay)"
      >
        <div
          class="time-div d-flex flex-column align-center justify-space-around"
        >
          <template v-if="$dayjs().isSame(timeslotDay.dateIso, 'day')">
            <span class="today text-body-1 font-weight-semibold">
              {{ $t("timeslots.today") }}
            </span>
          </template>
          <template v-else>
            <span class="day-week text-body-1">
              {{ $dayjs(timeslotDay.dateIso).format("dddd") }}
            </span>
            <span class="day-month text-h3 py-1">
              {{ $dayjs(timeslotDay.dateIso).format("DD") }}
            </span>
            <span class="day-month text-body-1">
              {{ $dayjs(timeslotDay.dateIso).format("MMMM") }}
            </span>
          </template>
        </div>
      </v-tab>
    </v-tabs>

    <h5 class="text-uppercase font-weight-semibold mt-6">
      {{ $t("timeslot.hoursTitle") }}
    </h5>
    <v-row
      no-gutters
      align="center"
      justify="start"
      class="range-buttons mt-2 mb-6"
    >
      <v-col
        cols="auto"
        sm="auto"
        v-for="(range, index) in timeslotRange"
        :key="index"
        class="d-flex justify-center mr-0 mr-sm-2"
      >
        <v-btn
          outlined
          class="rounded-md mr-3 mb-3"
          :class="
            rangeTab.toString() === range.periodId.toString()
              ? 'primary white--text active-border'
              : 'default--text text--darken-2'
          "
          :min-width="$vuetify.breakpoint.xs ? 'auto' : 120"
          :small="$vuetify.breakpoint.xs"
          data-test="range-btn"
          :value="range.periodId"
          min-height="38"
          @click="rangeTab = range.periodId"
        >
          <v-icon
            v-if="range.icon"
            class="mr-2 timeslot-period-icon"
            :color="rangeTab === range.periodId ? 'white' : 'default'"
            >{{ range.icon }}</v-icon
          >
          <span class="range-text font-weight-normal">{{
            $t(range.text)
          }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-tabs-items class="mb-4 mt-2" v-model="tab">
      <v-tab-item
        class="timeslot-container"
        v-for="timeslotDay in days"
        :key="timeslotDay.dateIso"
      >
        <v-row
          v-if="
            timeslotDay &&
              timeslotDay.timeslots &&
              timeslotDay.timeslots.length > 0
          "
        >
          <v-col
            cols="4"
            sm="3"
            v-for="timeslot in timeslotDay.timeslots.filter(
              checkDeliveryDayPeriodId
            )"
            :key="timeslot.timeslotId"
          >
            <!-- <span class="expires-time">{{
              expires(timeslot.cutoffDate, timeslot.cutoffTime)
            }}</span> -->
            <v-card
              outlined
              class="timeslot-card rounded-pill"
              :disabled="disabled"
              :class="[
                timeslot.status,
                { selected: timeslot.selected },
                $ebsn.meta(
                  timeslot.deliveryFee,
                  'metaData.deliveryfee_Info.CSS_CLASS'
                )
              ]"
              v-on:click="selectTimeslot(timeslot)"
            >
              <v-card-title class="justify-start flex-nowrap pa-0">
                <div class="time-frame py-2">
                  {{ timeslot.beginTime }} - {{ timeslot.endTime }}
                </div>
              </v-card-title>
              <!-- <v-card-subtitle class="text-center">
                {{ $t(`timeslots.availability.${timeslot.status}`) }}
              </v-card-subtitle> -->
            </v-card>
          </v-col>
        </v-row>
        <p v-else>
          <v-alert type="error" outlined>{{ $t("timeslots.noSlots") }}</v-alert>
        </p>
      </v-tab-item>
    </v-tabs-items>

    <v-row class="legend my-4" no-gutters>
      <v-col cols="6" sm="3" class="py-0">
        <div class="timeslot-legend">
          <div class="marker empty"></div>
          <span>Disponibile</span>
        </div>
      </v-col>
      <v-col cols="6" sm="3" class="py-0">
        <div class="timeslot-legend">
          <div class="marker intermediate"></div>
          <span>Quasi esaurita</span>
        </div>
      </v-col>
      <v-col cols="6" sm="3" class="py-0">
        <div class="timeslot-legend">
          <div class="marker full"></div>
          <span>Non disponibile</span>
        </div>
      </v-col>
      <v-col cols="6" sm="3" class="py-0">
        <div class="timeslot-legend">
          <div class="marker selected"></div>
          <span>Selezionata</span>
        </div>
      </v-col>
    </v-row>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
      :container="false"
    />
  </div>
</template>
<style lang="scss">
.timeslot-selector {
  .timeslot-legend {
    margin-bottom: 10px;
  }
  .range-text,
  .time-frame {
    font-weight: 400 !important;
  }
  h3 {
    text-align: center;
  }
  .days-tabs {
    .days-tab {
      text-transform: capitalize !important;
      min-width: 130px;
      border: 1px solid #dadbdc;
      // background-color: var(--v-grey-lighten2);
      border-radius: 10px;
      .time-div {
        color: var(--v-default-base);
      }
      &.v-tab--active {
        border: 2px solid var(--v-primary-base);
        background-color: var(--v-primary-base);
        .time-div {
          color: var(--v-white-base);
        }
      }
    }
  }
  .timeslot-card {
    .v-card__title {
      padding: 6px;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 16px;
      }
    }
    &.empty {
      border-color: var(--v-grey-base);
    }
    &.selected {
      border: 1px solid var(--v-primary-base) !important;
      .v-card__title,
      .v-card__subtitle {
        color: var(--v-white-base);
      }
      background-color: var(--v-primary-base);
      .time-frame {
        color: var(--v-white-base);
      }
      &.intermediate {
        .v-card__title .time-frame {
          color: var(--v-default-base);
        }
      }
    }
    &.intermediate {
      background-color: #fff4c8;
      border-color: var(--v-grey-base);
      .v-card__title,
      .v-card__subtitle {
        border-color: var(--v-accent-base);
      }
    }
    &.full {
      border-color: var(--v-grey-base);
      background-color: var(--v-grey-lighten1);
      .v-card__title {
        color: var(--v-default-base);
      }
    }
    &.promo-yellow-azure {
      .time-price {
        background-color: var(--v-accent-base);
        color: var(--v-secondary-base);
      }
    }
    &.promo-only-azure {
      .time-price {
        margin: -1px;
        color: var(--v-secondary-base);
        border: 1px solid var(--v-secondary-base);
        border-top-right-radius: $border-radius-root;
        border-bottom-right-radius: $border-radius-root;
      }
    }
    &.promo-red-white {
      .time-price {
        background-color: var(--v-primary-base);
        color: var(--v-white-base);
      }
    }
    &.promo-red-white-promo {
      .time-price {
        background-color: var(--v-primary-base);
        color: var(--v-white-base);
        line-height: 1rem;
        white-space: initial;
        &::before {
          content: "PROMO";
          font-size: 11px;
          font-weight: normal;
        }
      }
    }
    .time-frame {
      font-size: 13px;
      font-weight: 600;
      flex-grow: 1;
      text-align: center;
    }
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      .time-frame {
        font-size: 14px;
      }
    }
    .time-price {
      text-align: center;
      border-left: 1px solid var(--v-grey-base);
      font-size: 16px;
      width: 40%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: nowrap;
    }
  }

  .expires-time {
    font-size: 10px;
    text-transform: uppercase;
    padding-left: 10px;
  }
  .legend {
    margin: 3px;
    display: flex;
    align-items: center;
    .marker {
      width: 32px;
      height: 32px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      border-radius: 4px;
    }
    .empty {
      color: #f8f9fa;
      background-color: #fff;
      // border: 1px solid #e5e5e5;
      border: 2px solid #e9e9e9;
    }
    .intermediate {
      color: #5b5b5b;
      background-color: #faffb7;
    }
    .full {
      color: #f8f9fa;
      background-color: var(--v-grey-lighten1);
    }
    .selected {
      color: #fff;
      background-color: var(--v-primary-base);
    }
    span {
      vertical-align: middle;
      font-size: 16px;
      color: $text-color;
    }
    .timeslot-card {
      .v-card__title {
        font-size: 14px;
      }
    }
  }
}
</style>
<script>
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";

import { mapState } from "vuex";

import get from "lodash/get";
import cartInfo from "~/mixins/cartInfo";

export default {
  name: "TimeslotSelector",
  mixins: [cartInfo],
  data() {
    return {
      days: [],
      tab: 0,
      category: {},
      selectedTimeslotDay: {},
      disabled: false,
      rangeTab: 0,
      timeslotRange: {
        0: { periodId: [1, 4], icon: "$day", text: "timeslots.morning" },
        1: {
          periodId: [2, 3, 5, 6],
          icon: "$afternoon",
          text: "timeslots.afternoon"
        },
        2: { periodId: [0], text: "timeslots.allTimeRanges" }
      }
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    description() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    }
  },
  methods: {
    openAddressSelector() {
      this.$emit("submit", "setAddress");
    },
    selectTimeslotDay(day) {
      this.selectedTimeslotDay = day;
    },
    async selectTimeslot(timeslot) {
      let _this = this;
      if (timeslot.active < 1) {
        return;
      }
      _this.disabled = true;
      let data = await _this.$store.dispatch("cart/setTimeslot", {
        dateIso: _this.selectedTimeslotDay.dateIso,
        timeslotId: timeslot.timeslotId
      });
      if (data) {
        this.$emit("submit", true);
      }
    },
    async fetchTimeslots() {
      let _this = this;
      _this.days = await DeliveryService.getTimeslotList(false);
      if (_this.days.length > 1 && _this.days[0].timeslots.length == 0) {
        _this.days.shift();
      }
      for (var i = 0; i < _this.days.length; i++) {
        if (_this.days[i].dateIso == _this.cart.timeslot.date) {
          _this.tab = i;
          this.selectedTimeslotDay = _this.days[i];
          break;
        }
      }
      if (!_this.selectedTimeslotDay.dateIso) {
        _this.selectTimeslotDay(_this.days[0]);
      }

      return false;
    },
    expires(day, time) {
      let today = this.$dayjs();
      let date = this.$dayjs(day + " " + time, "DD/MM/YYYY hh:mm");
      let diff =
        date.diff(today, "days") > 0
          ? date.diff(today, "days") + " giorni "
          : date.diff(today, "hours") > 0
          ? date.diff(today, "hours") + " ore "
          : date.diff(today, "minute") + " minuti";

      return "scade tra " + diff;
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug("timeslot");
      } catch (e) {
        console.log(e);
      }
    },
    checkIfIsToday(date) {
      return this.$dayjs().isSame(date, "day");
    },
    checkDeliveryDayPeriodId(item) {
      return (
        this.rangeTab == 0 || this.rangeTab.includes(item.deliveryDayPeriodId)
      );
    }
  },
  mounted() {
    this.disabled = false;
    this.fetchTimeslots();
    this.fetchCategory();
  }
};
</script>
