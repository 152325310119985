<template functional>
  <div
    @click.stop.prevent
    class="product-qty-wrapper d-flex align-center justify-center"
  >
    <div
      v-if="props.item"
      class="qty-wrap pa-1 d-flex align-center rounded-pill"
      @mousedown.stop
    >
      <a
        role="button"
        class="minus rounded-circle mx-auto d-flex align-center justify-center"
        v-ripple
        @click.stop.prevent="
          parent.minus(true, { infos: { ...props.selectedOptions } })
        "
        @mousedown.stop
      >
        <!-- v-ripple -->
        <v-icon size="20" color="black">$minus</v-icon>
      </a>
      <div class="val-cnt mx-auto d-flex align-center justify-center">
        <span class="val">{{ parent.quantity }} {{ parent.unit }}</span>
        <span class="small">{{ parent.quantityPerUnit }}</span>
      </div>
      <a
        role="button"
        class="plus mx-auto d-flex align-center justify-center"
        :class="{ ' rounded-circle': !props.full, 'rounded-pill': props.full }"
        v-ripple
        @click.stop.prevent="
          $options.handlePlus(parent.plus, props.product, parent.quantity, {
            infos: {
              ...props.selectedOptions,
              ...$options.packageNumCartInfo(props.product, props.packageNum)
            }
          })
        "
      >
        <!-- v-ripple -->
        <v-icon size="20" color="black">$plus</v-icon>
      </a>
    </div>
    <div v-else>
      <v-btn
        elevation="0"
        color="primary"
        class="mx-auto d-flex align-center rounded-pill justify-center"
        v-ripple
        small
        fab
        @click.stop.prevent="
          $options.handlePlus(parent.plus, props.product, parent.quantity, {
            infos: {
              ...props.selectedOptions,
              ...$options.packageNumCartInfo(props.product, props.packageNum)
            }
          })
        "
        ><v-icon size="20">$cart</v-icon></v-btn
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductQty",
  props: {
    product: { type: Object, required: true },
    item: { type: Object, required: false },
    updateCart: { type: Boolean, default: global.config.updateCart },
    full: { type: Boolean, default: false },
    selectedOptions: { type: Object },
    packageNum: { type: String, required: false }
  },
  packageNumCartInfo(product, packageNum) {
    if (product.productInfos?.SPLIT_ON_PACKAGE) {
      return { package_num: packageNum };
    }
    return null;
  },
  async handlePlus(plusFunction, product, quantity, params, searchParams) {
    const tempQtyMult = product.productInfos.QTY_MULT;
    if (
      product &&
      product.warehousePromo &&
      product.warehousePromo.view &&
      product.warehousePromo.view.MPieces &&
      !product.warehousePromo.view.cssClass.includes("mixmatch_sconto") &&
      product.productInfos.QTY_LOCKED !== "true"
    ) {
      if (quantity < 1) {
        product.productInfos.QTY_MULT = product.warehousePromo.view.MPieces;
      }
    }
    await plusFunction(true, params, searchParams);
    product.productInfos.QTY_MULT = tempQtyMult;
  },
  handleMinus(minusFunction, product, quantity, params, searchParams) {
    minusFunction(true, params, searchParams);
  }
};
</script>
