<template>
  <div
    class="d-flex align-center not-available-product product-day-lock-container ml-2 mr-2"
  >
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div
          class="day-lock"
          dark
          v-bind="attrs"
          v-on="on"
          @click.prevent=""
          @mousedown.stop
        >
          <v-icon class="day-lock-icon" color="primary">$calendar</v-icon>
          <!-- <span v-html="day.shortText"></span> -->
        </div>
      </template>
      <div class="day-lock-tooltip-description">
        <span v-html="dayLocks.longText"></span>
      </div>
    </v-tooltip>
  </div>
</template>
<style lang="scss">
.day-lock-tooltip-description {
  width: 300px;
}
.product-day-lock-container {
  .day-lock-icon {
    font-size: 30px;
  }
}
</style>
<script>
import split from "lodash/split";
export default {
  name: "ProductDayLock",
  props: ["days"],
  computed: {
    dayLocks() {
      //aggiornamento: a differenza di quanto detto in call, lo split va fatto per " " o per ","
      let array = split(this.days, " ");
      // patch for the new version
      if (this.days.indexOf(",") > -1) {
        array = split(this.days, ",");
      }
      var result = [];
      result.longText = this.$t("dayLock.overlayText");
      for (var i = 0; i < array.length; i++) {
        result[i] = {};
        if (array[i].indexOf("_") > -1) {
          var daySplitted = array[i].split("_");
          // result[i].shortText =
          //   this.$t("dayLock.weekDaysShort." + daySplitted[0]) +
          //   " " +
          //   this.$t("dayLock.timeDayShort." + daySplitted[1]);
          // result[i].longText =
          //   this.$t("dayLock.overlayText") +
          //   this.$t("dayLock.weekDays." + daySplitted[0]) +
          //   " " +
          //   this.$t("dayLock.timeDay." + daySplitted[1]) +
          //   ".</br>";
          result.longText +=
            this.$t("dayLock.weekDays." + daySplitted[0]) +
            " " +
            this.$t("dayLock.timeDay." + daySplitted[1]);
        } else {
          // result[i].shortText = this.$t("dayLock.weekDaysShort." + array[i]);
          // result[i].longText =
          //   this.$t("dayLock.overlayText") +
          //   this.$t("dayLock.weekDays." + array[i]) +
          //   ".</br>";
          result.longText += this.$t("dayLock.weekDays." + array[i]);
        }
        result.longText += i == array.length - 1 ? "." : ", ";
      }
      return result;
    }
  }
};
</script>
