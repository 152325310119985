<template>
  <v-container>
    <v-row cols="12">
      <v-col cols="12" md="6" lg="6" sm="12">
        <div id="loginDiv" ref="loginDiv"></div>
      </v-col>
      <v-col cols="12" md="6" lg="6" sm="12" class="alt-login">
        <h2 class="d-flex justify-center login-title">
          Non sei ancora registrato?
        </h2>
        <div class="py-5 grey lighten-1 d-flex justify-center flex-column">
          <v-row class="d-flex justify-center py-5">
            <img
              class="fidelity-image"
              width="95"
              alt="advantage-card"
              src="/img_layout/advantage-card.png"
            />
          </v-row>
          <v-row class="d-flex justify-center login-descr">
            <span><b>Sei già cliente Iper?</b></span
            ><br />
            <span class="px-12 d-flex justify-center align-center text-center">
              Se hai già una carta vantaggi inserisci il numero della carta
              esistente altrimenti non potrai usufruire dei buoni sconto erogati
              su quella carta.
            </span>
          </v-row>
          <v-form
            ref="form"
            class="w-100"
            v-model="valid"
            @submit.prevent.stop="registerWithFidelity()"
          >
            <v-card-text>
              <v-text-field
                v-model="fidelityCard"
                :error-messages="errors"
                label="Carta Vantaggi"
                autocomplete="off"
                required
                :rules="fidelityCardRules"
                dense
                outlined
                clearable
                class="w-100 elevation-0 fidelity-form-field"
                style="border-radius: 25px"
              ></v-text-field>
              <v-text-field
                v-model="lastName"
                label="Cognome"
                autocomplete="off"
                :rules="genericPeopleNameRule"
                required
                dense
                outlined
                clearable
                class="w-100 elevation-0 fidelity-form-field"
              ></v-text-field>
              <v-btn
                :loading="loading"
                type="submit"
                color="primary"
                large
                block
                depressed
                class="w-100 primary elevation-0 px-8"
              >
                Registrati
              </v-btn>
              <ResponseMessage :response="response" class="mt-3" />
            </v-card-text>
          </v-form>
        </div>
        <v-container>
          <v-card class="elevation-0 px-6 px-sm-7 pb-5">
            <h2 class="d-flex justify-center login-title">
              Non hai la Carta Vantaggi?
            </h2>
            <v-card-actions>
              <div
                class="d-flex flex-column w-100 align-center mt-2"
                v-if="enableRegistration"
              >
                <v-btn
                  @click.stop.prevent="handleRegister()"
                  class="w-100 primary elevation-0 px-8"
                  large
                  style="border-radius: 25px"
                >
                  {{ $t("login.buttonRegister") }}
                </v-btn>
                <a
                  @click.stop.prevent="openDeliveryServiceSelector()"
                  class="py-8 default--text"
                >
                  <b style="text-decoration: underline;">Accedi come ospite</b>
                </a>
              </div>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
    <v-overlay :value="isAuthLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import pushNotification from "~/mixins/pushNotification";
import { mapActions, mapGetters } from "vuex";
import ResponseMessage from "@/components/ResponseMessage";
import customService from "@/service/customService";
import AddressSelector from "./delivery/AddressSelector.vue";

import {
  requiredValue,
  isNumber,
  genericPeopleName
} from "~/validator/validationRules";
export default {
  name: "Login",
  props: {
    hideLogo: { type: Boolean, required: false, default: false },
    hideRegistration: { type: Boolean, required: false, default: false },
    redirectParams: { type: Object, required: false }
  },
  mixins: [pushNotification],
  components: { ResponseMessage },
  data() {
    return {
      loadingCardCode: null,
      isAuthLoading: false,
      showPassword: false,
      rememberMe: true,
      lazy: true,
      fidelityCardRules: [
        requiredValue(),
        isNumber("Deve contenere solo cifre")
      ],
      genericPeopleNameRule: [requiredValue(), genericPeopleName()],
      invalid: null,
      loading: null,
      fidelityCard: "",
      lastName: "",
      response: {},
      valid: true,
      errors: [],
      isScreensetLoaded: false,
      enableRegistration: process.env.VUE_APP_ENABLE_REGISTRATION === "true"
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  },
  methods: {
    ...mapActions({
      doLogin: "cart/doLogin",
      loadCart: "cart/loadCart"
    }),
    async setDeliveryService(deliveryServiceId) {
      var defaultConfig = {
        waitForResult: false,
        width: 530,
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        deliveryServiceId: deliveryServiceId
      };
      var config = Object.assign({}, null, defaultConfig);
      this.dialogInstance = await this.$dialog.show(AddressSelector, config);
    },
    async registerWithFidelity() {
      let profile = await customService.registerWithFidelity(
        this.fidelityCard,
        this.lastName
      );
      if (profile?.response?.status === 0) {
        this.$router.push({
          name: "RegistrationUser",
          params: { profile, regWithCard: true }
        });
        document.querySelector(".vuedl-layout__closeBtn").click();
      }
    },
    async openDeliveryServiceSelector() {
      this.$emit("submit", false);
      this.setDeliveryService(1);
      // document.querySelector(".vuedl-layout__closeBtn").click();
    },
    async handleSubmit(username, password) {
      let token = null;
      if (this.$recaptchaLoaded) {
        await this.$recaptchaLoaded();
        token = await this.$recaptcha("login");
      }
      if (password.length > 0) {
        try {
          this.isAuthLoading = true;
          const resLogin = await this.doLogin({
            email: username,
            password: password,
            rememberMe: this.rememberMe,
            token
          });
          window.localStorage.removeItem("loginId");
          if (this.isAuthenticated) {
            global.EventBus.$emit("login");
            this.$emit("loggedIn", true);
          }
          this.isAuthLoading = false;
          this.$emit("submit", true);
          if (resLogin && resLogin.user?.fidelityCard?.length > 0) {
            this.$router.push("/");
          } else {
            this.$router.push({ name: "ProfileUpdateInternal" });
          }
          setTimeout(() => {
            const closeButtons = document.querySelectorAll(
              ".vuedl-layout__closeBtn"
            );

            closeButtons.forEach(button => {
              // Check if the sibling .container has a child with the .alt-login class
              const container = button
                .closest(".vuedl-layout")
                .querySelector(".container");
              if (container && container.querySelector(".alt-login")) {
                button.click(); // Click the close button if the condition is met
              }
            });
          }, 600);
        } catch (response) {
          this.response = response;
        } finally {
          this.loading = false;
        }
      }
      window.localStorage.removeItem("loginId");
    },
    handleRegister() {
      this.$router.push({
        name: "RegistrationUser",
        path: "/registration-user"
      });
      this.$emit("submit", false);
    },
    loadGigyaScreenSet() {
      let there = this;
      const gigyaInstance = window.gigya;

      if (gigyaInstance) {
        gigyaInstance.accounts.addEventHandlers({
          onLogin: there.onLoginHandler,
          onLogout: there.onLogoutHandler
        });
        let preloadContainer = document.getElementById("gigya-preload");

        if (preloadContainer && preloadContainer.innerHTML.length > 0) {
          preloadContainer.style.display = "block";
          // Move the children elements (not just copying the innerHTML)
          this.$refs.loginDiv.appendChild(preloadContainer);
        } else {
          console.log("Preload container is empty");
          this.$nextTick(() => {
            gigyaInstance.accounts.showScreenSet({
              screenSet: "Iperdrive-RegistrationLogin",
              startScreen: "gigya-login-screen",
              showTermsLink: false,
              containerID: "loginDiv",
              onLogin: there.onLoginHandler,
              onError: error => {
                console.error("bbb Error loading Gigya screenset", error);
              }
            });
          });
        }
      } else {
        console.log("bbb Login.vue NO Gigya instance");
        this.$nextTick(() => {
          gigyaInstance.accounts.showScreenSet({
            screenSet: "Iperdrive-RegistrationLogin",
            startScreen: "gigya-login-screen",
            showTermsLink: false,
            containerID: "loginDiv",
            onLogin: there.onLoginHandler,
            onError: error => {
              console.error("bbb Error loading Gigya screenset", error);
            }
          });
        });
      }
    },
    onLoginHandler(response) {
      let uid = response.UID;
      let uidSig = response.UIDSignature;
      let sigTStamp = response.signatureTimestamp;
      let pwd = `${uidSig}:${sigTStamp}`;
      const loginId = window.localStorage.getItem("loginId");
      if (!loginId) {
        this.handleSubmit(uid, pwd);
        window.localStorage.setItem("loginId", uid);
      }
    }
  },
  created() {
    global.EventBus.$on("gigya-loaded", function() {
      console.log("bbb Gigya loaded captured!");
      this.loadGigyaScreenSet();
    });
  },
  mounted() {
    this.loadGigyaScreenSet();
  }
};
</script>

<style lang="scss">
.login-title {
  font-size: 24px;
}
.alt-login {
  @media (max-width: 600px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.fidelity-form-field {
  border-radius: 25px;
  .v-input__control {
    .v-input__slot {
      background-color: #fff;
    }
  }
}
</style>
