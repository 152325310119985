<template>
  <v-card
    :href="link"
    @click.prevent="handleLink"
    :style="cardStyle"
    class="proposal-card-overlay"
    v-intersect.once="handleView"
  >
    <v-img
      :src="imgSrc"
      :alt="proposal.imgAlt"
      :title="proposal.imgDescription"
      eager
    >
      <div
        class="blurred-bg"
        v-if="
          $ebsn.meta(
            proposal,
            'metaData.category_proposal_type.SHOW_BLACK_LAYER'
          )
        "
      ></div>
      <div
        class="text-overlay"
        :class="[
          $ebsn.meta(
            proposal,
            'metaData.category_proposal_type.TEXT_ALIGN',
            ' text-align-left '
          ),
          $ebsn.meta(
            proposal,
            'metaData.category_proposal_type.TEXT_ALIGN_VERTICAL',
            ' text-align-vertical-bottom '
          )
        ]"
      >
        <div class="text-overlay-content">
          <v-card-title
            v-if="proposal.descr"
            v-html="proposal.descr"
          ></v-card-title>
          <v-card-text
            v-if="proposal.content"
            v-html="proposal.content"
          ></v-card-text>
          <v-card-actions
            v-if="
              $ebsn.meta(proposal, 'metaData.category_proposal_type.BTN_TEXT')
            "
          >
            <v-btn
              :href="link"
              :outlined="
                $ebsn.meta(
                  proposal,
                  'metaData.category_proposal_type.BTN_OUTLINED',
                  false
                )
              "
              :color="
                $ebsn.meta(
                  proposal,
                  'metaData.category_proposal_type.BTN_COLOR'
                )
              "
              >{{
                $ebsn.meta(proposal, "metaData.category_proposal_type.BTN_TEXT")
              }}</v-btn
            >
          </v-card-actions>
        </div>
      </div>
    </v-img>
  </v-card>
</template>
<style lang="scss">
.proposal-card-overlay.v-card {
  overflow: hidden;
  height: 100%;
  .blurred-bg:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 30%, black 100%);
  }
  .v-card__title,
  .v-card__text {
    color: var(--v-white-base);
  }
  .v-image {
    height: 100%;
    color: inherit;
  }
  .text-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    .text-overlay-content {
      padding: 12px;
    }
    &.text-align-left {
      justify-content: flex-start;
      .v-card__actions {
        justify-content: flex-start;
      }
    }
    &.text-align-right {
      justify-content: flex-end;
      .v-card__actions {
        justify-content: flex-end;
      }
    }
    &.text-align-center {
      justify-content: center;
    }
    &.text-align-vertical-bottom {
      align-items: flex-end;
    }
    &.text-align-vertical-top {
      align-items: flex-start;
    }
    &.text-align-vertical-center {
      align-items: center;
    }
  }
}
</style>
<script>
import banner from "~/mixins/banner";

import get from "lodash/get";

export default {
  name: "ProposalImage",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner],
  computed: {
    cardStyle() {
      let style = {};
      let backgroundColor = get(
        this.proposal,
        `metaData.category_proposal_type.BACKGROUND_COLOR`
      );
      if (backgroundColor) {
        style.backgroundColor = backgroundColor;
      }
      let textColor = get(
        this.proposal,
        `metaData.category_proposal_type.TEXT_COLOR`
      );
      if (textColor) {
        style.color = textColor;
      }

      return style;
    },
    imgSrc() {
      let prop =
        "metaData.category_proposal_type." +
        (!this.$vuetify.breakpoint.xsOnly ? "IMAGE_DESKTOP" : "IMAGE_MOBILE");
      return get(this.proposal, prop, this.proposal.img);
    }
  }
};
</script>
