var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-price d-flex justify-center ml-2 mr-auto"},[(_vm.product.productInfos.MAXACQ)?_c('span',{staticClass:"max-purchasable smaller"},[_vm._v(" max "+_vm._s(_vm.product.productInfos.MAXACQ)+" ")]):_vm._e(),(
      _vm.checkout &&
        _vm.product?.warehousePromo?.view?.MPieces &&
        _vm.product?.warehousePromo?.view?.bubbleCheckout
    )?_c('div',{domProps:{"innerHTML":_vm._s(_vm.product?.warehousePromo?.view?.bubbleCheckout)}}):_c('div',[(_vm.showStandardPrice)?_c('div',{staticClass:"old-price"},[_vm._v(" "+_vm._s(_vm.$n( _vm.$options.confezione( _vm.product.productInfos.TIPOLOGIA, _vm.product.priceStandardDisplay, _vm.product.productInfos.WEIGHT_SELLING ), "currency" ))+" "),(
          _vm.product.productInfos.TIPOLOGIA == 'Sfuso' ||
            _vm.product.productInfos.TIPOLOGIA == 'Confezione'
        )?_c('span',{staticClass:"unit-price"},[_vm._v("/"+_vm._s(_vm.product.weightUnitDisplay)+" ")]):_vm._e()]):_vm._e()]),(
      _vm.product.warehousePromo &&
        _vm.product.warehousePromo.warehousePromoTypeId == 1
    )?_c('div',{staticClass:"d-flex flex-column justify-space-between align-end price-container mt-auto"},[_c('div',{staticClass:"cur-price w-100"},[_vm._v(" "+_vm._s(_vm.$n( _vm.$options.confezione( _vm.product.productInfos.TIPOLOGIA, _vm.showStandardDisplay ? _vm.product.priceStandardDisplay : _vm.product.priceDisplay, _vm.product.productInfos.WEIGHT_SELLING ), "currency" ))+" "),(
          _vm.product.productInfos.TIPOLOGIA == 'Sfuso' ||
            _vm.product.productInfos.TIPOLOGIA == 'Confezione'
        )?_c('span',{staticClass:"unit-price"},[_vm._v("/"+_vm._s(_vm.product.weightUnitDisplay)+" ")]):_vm._e()])]):_c('div',{staticClass:"d-flex justify-space-between align-end price-container mt-auto"},[_c('div',{staticClass:"cur-price"},[_vm._v(" "+_vm._s(_vm.$n( _vm.$options.confezione( _vm.product.productInfos.TIPOLOGIA, _vm.showStandardDisplay ? _vm.product.priceStandardDisplay : _vm.product.priceDisplay, _vm.product.productInfos.WEIGHT_SELLING ), "currency" ))+" "),(
          _vm.product.productInfos.TIPOLOGIA == 'Sfuso' ||
            _vm.product.productInfos.TIPOLOGIA == 'Confezione'
        )?_c('span',{staticClass:"unit-price"},[_vm._v("/"+_vm._s(_vm.product.weightUnitDisplay)+" ")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }