import Vue from "vue";
import Vuetify, {
  VRow,
  VCol,
  VTextField,
  VTooltip,
  VCheckbox,
  VSelect,
  VTextarea,
  VCard,
  VCardTitle,
  VCardSubtitle,
  VDatePicker,
  VColorPicker,
  VFileInput
} from "vuetify/lib";
import i18n from "./i18n_REMOTE";
import "@/scss/_fonts.scss";
import {
  Ripple,
  Intersect,
  Touch,
  Resize,
  ClickOutside
} from "vuetify/lib/directives";
import VFormText from "@/components/forms/FormText.vue";
import VFormFileViewer from "@/components/forms/FormFileViewer.vue";

Vue.use(Vuetify, {
  components: {
    VRow,
    VTooltip,
    VCol,
    VTextField,
    VCheckbox,
    VSelect,
    VTextarea,
    VCard,
    VCardTitle,
    VCardSubtitle,
    VDatePicker,
    VColorPicker,
    VFileInput,
    VFormText,
    VFormFileViewer
  },
  directives: { Ripple, Intersect, Touch, Resize, ClickOutside }
});

export default new Vuetify({
  theme: {
    //disable: true,
    light: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        logo: {
          default: "/logo/logo.png",
          mobile: "/logo/logo.png"
        },
        default: {
          base: "#3D3D3D",
          lighten1: "#534741"
        },
        primary: {
          base: "#FFA300",
          lighten1: "#FFC867",
          lighten2: "#FFE5B8",
          darken1: "#D98B00",
          darken2: "#A16700"
        },
        secondary: {
          base: "#FFA300",
          lighten1: "#FFC867",
          lighten2: "#FFE5B8",
          darken1: "#D98B00",
          darken2: "#A16700"
        },
        accent: {
          base: "#FFA300"
        },
        white: "#FFFFFF",
        grey: {
          base: "#8D8D8D",
          lighten1: "#DADBDC",
          lighten2: "#F8F9FA",
          darken1: "#3D3D3D"
        },
        warning: "#FFA300"
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    iconfont: "icon",
    values: {
      leadtime: "icon-leadtime",
      fidelity: "icon-fidelity",
      arrowRight: "icon-arrow-right",
      arrowLeft: "icon-arrow-left",
      sparkle: "icon-sparkle",
      grid: "icon-grid",
      listUl: "icon-list-ul",
      cartArrowDown: "icon-cart-arrow-down",
      listCheck: "icon-list-check",
      triangleExclamation: "icon-triangle-exclamation",
      fileContract: "icon-file-contract",
      messagesQuestion: "icon-messages-question",
      commentSmile: "icon-comment-smile",
      locationCrosshairs: "icon-location-crosshairs",
      doorOpen: "icon-door-open",
      trashXmark: "icon-trash-xmark",
      calendarPen: "icon-calendar-pen",
      vault: "icon-vault",
      barsFilter: "icon-bars-filter",
      starLight: "icon-star-light",
      afternoon: "icon-afternoon",
      day: "icon-day",
      iperI: "icon-iper-i",
      download: "icon-download",
      done: "icon-checked",
      copy: "icon-copy",
      myLocation: "icon-my_location",
      map: "icon-world",
      schedule: "icon-calendar-clock-regular",
      call: "icon-callXX",
      arrowBack: "icon-arrow_left",
      arrowDownward: "icon-arrow_down",
      arrowForward: "icon-arrow_right",
      basket: "icon-basket",
      calendar: "icon-calendar-date",
      event: "icon-event",
      fastSearch: "icon-lente",
      location: "icon-location",
      arrowTopLeft: "icon-arrow_up",
      buoni: "icon-buoni",
      cart: "icon-cart",
      cart1: "icon-cart1",
      cartReorder: "icon-acquista-di-nuovo",
      chevronDown: "icon-chevron_down",
      chevronLeft: "icon-chevron_left",
      chevronRight: "icon-chevron_right",
      chevronUp: "icon-chevron_up",
      creditCard: "icon-credit-card1",
      card: "icon-credit-card1",
      eye: "icon-eye",
      eyeOff: "icon-eye_off",
      filter: "icon-filter_list",
      heart: "icon-liste-preferiti",
      heartfull: "icon-heart-full",
      home: "icon-home",
      locker: "icon-locker",
      drive: "icon-drive",
      onboard: "icon-Coop_on_board",
      indirizzi: "icon-punti-vendita",
      minus: "icon-minus1",
      plus: "icon-plus1",
      profile: "icon-user",
      user1: "icon-user1",
      profileFull: "icon-user_black",
      search: "icon-lente",
      settings: "icon-settings",
      star: "icon-star",
      starFull: "icon-star_full",
      twitter: "icon-twitter",
      youtube: "icon-youtubeXXX",
      instagram: "icon-instagramXXX",
      facebook: "icon-facebook",
      linkedin: "icon-linkedinDDD",
      like: "icon-like",
      vantaggi: "icon-vantaggi",
      pagamento: "icon-metodi-pagamento",
      wallet: "icon-wallet",
      liste: "icon-liste-preferiti",
      invoice: "icon-invoice",
      addUser: "icon-add-user",
      //icone standard vuetify da codificare sempre
      complete: "icon-checked",
      cancel: "icon-delete",
      close: "icon-delete",
      delete: "icon-delete",
      clear: "icon-delete",
      success: "icon-checked",
      info: "icon-info",
      warning: "icon-warning",
      error: "icon-info",
      prev: "icon-chevron_left",
      next: "icon-chevron_right",
      checkboxOff: " icon-check_box_outline_blank",
      checkboxOn: "icon-check_box",
      checkboxIndeterminate: "...",
      delimiter: "...", // for carousel
      sort: "...",
      expand: "icon-chevron_down",
      menu: "icon-menu",
      subgroup: "...",
      dropdown: "icon-chevron_down",
      radioOn: "icon-radio-checked",
      radioOff: "icon-radio-unchecked",
      edit: "icon-edit",
      print: "icon-printer",
      ratingEmpty: "icon-star_outline",
      ratingFull: "icon-star_full",
      ratingHalf: "icon-star_half",
      loading: "icon-cached",
      first: "icon-first_page",
      last: "icon-last_page",
      unfold: "icon-unfold",
      file: "icon-file",
      more_vert: "icon-more_vert",
      qrcode: "icon-qrcode",
      barcode: "icon-barcode",
      barcodeScan: "icon-barcode-scan",
      tag: "icon-tag",
      percent: "icon-percent",
      logout: "icon-logout",
      receipt: "icon-receipt",
      course: "icon-course",
      clock: "icon-clock",
      chef: "icon-chef",
      ingredients: "icon-ingredients",
      delivery: "icon-delivery",
      cashback: "icon-cashback",
      verticalDots: "icon-vertical-dots",
      bellLight: "icon-bell-light",
      fullscreen: "icon-fullscreen",
      closeFullscreen: "icon-close-fullscreen",
      speciali: "icon-speciali",
      promo: "icon-promo",
      frutta_verdura: "icon-frutta_verdura",
      pesce: "icon-pesce",
      carne_salumi: "icon-carne_salumi",
      formaggi_latte: "icon-formaggi_latte",
      dispensa: "icon-dispensa",
      pane_pizza: "icon-pane_pizza",
      gastronomia: "icon-gastronomia",
      surgelati_gelati: "icon-surgelati_gelati",
      bevande: "icon-bevande",
      vini: "icon-vini",
      bimbo: "icon-bimbo",
      persona: "icon-persona",
      casa: "icon-casa",
      animali: "icon-animali",
      cancelleria: "icon-cancelleria",
      giardino: "icon-giardino"
    }
  }
});
