<template>
  <v-card
    v-if="path"
    class="custom-confirm-dialog"
    :class="dialogClass"
    @click="clicked"
  >
    <v-card-title
      v-if="$te(path + '.title')"
      v-html="$t(path + '.title', [...paramTitle])"
      class="title"
    >
    </v-card-title>
    <v-card-text>
      <p
        v-if="$te(path + '.content')"
        v-html="$t(path + '.content', [...paramContent])"
        class="content"
      ></p>
      <p
        v-if="$te(path + '.content2')"
        v-html="$t(path + '.content2', [...paramContent2])"
        class="content2"
      ></p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        outlined
        depressed
        @click="refused"
        v-html="$t('common.no')"
      >
      </v-btn>
      <v-btn
        color="primary"
        @click="agreed"
        depressed
        v-html="$t('common.yes')"
      >
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.custom-confirm-dialog {
  .title {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .content,
  .content2 {
    font-size: 1rem;
  }
  // .content {
  // color: var(--v-error-base);
  // }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "CustomConfirmDialog",
  mixins: [clickHandler],
  props: {
    path: { type: String, required: true },
    paramTitle: { type: Array, default: () => [] },
    paramContent: { type: Array, default: () => [] },
    paramContent2: { type: Array, default: () => [] },
    dialogClass: { type: String, default: "" }
  },
  data() {
    return {
      opened: true
    };
  },
  computed: {},
  methods: {
    agreed() {
      window.open("/page/diventa-socio-iper", "_blank");
      this.$emit("submit", true);
    },
    refused() {
      this.$emit("submit", false);
    }
  }
};
</script>
