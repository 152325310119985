<template>
  <v-card
    outlined
    class="product-card d-flex flex-column"
    :class="{ 'highlited-product': isHighlighted }"
    :id="`product-card-${product.slug}`"
    :to="{ name: 'Product', params: { slug: product.slug } }"
    ref="productCard"
    :key="componentKey"
  >
    <div class="product" @click="$emit('selectProduct')">
      <div
        v-if="isAuthenticated && product?.warehousePromo?.expireDate"
        class="promo-expire-date"
        :class="{ 'promo-fidelity-text': promoFidelity }"
      >
        Fino al {{ product.warehousePromo.expireDate }}
      </div>
      <div class="d-flex justify-end align-center w-100 top">
        <div class="d-flex align-center">
          <img
            v-if="vendor"
            height="36"
            :src="vendor.iconSource"
            :alt="`Immagine marchio ${vendor.name}`"
          />
          <v-tooltip left :key="heartKey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click.stop.prevent="addToFavorites"
                @mousedown.stop
                aria-label="Aggiungi ad una lista"
              >
                <transition name="pulse">
                  <v-icon color="primary" :key="highlight">
                    {{ highlight ? "$star" : "$starLight" }}
                  </v-icon>
                </transition>
              </v-btn>
            </template>
            <span>{{ $t("product.addToList") }}</span>
          </v-tooltip>
        </div>
      </div>
      <ProductAcqLimit :product="product" />
      <div class="w-100">
        <div class="d-flex flex-column body">
          <img
            width="170"
            height="170"
            :src="product.mediaURL"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            class="product-img
          align-self-center"
            :alt="'Immagine' + product.name"
            :title="
              product.name + ' (' + product.codInt + '-' + product.codVar + ')'
            "
          />
          <div
            v-if="isHighlighted && !product.warehousePromo"
            class="product-highlighted-container"
          >
            <div class="highlighted-box-text">
              {{ $t("product.highlighted") }}
            </div>
          </div>
          <div
            class="description default--text d-flex flex-column justify-left w-100 mt-3 ml-1 ml-sm-0"
          >
            <span class="name">
              {{ product.name }}
            </span>
            <div class="d-flex flex-column align-start">
              <span class="short_descr text-uppercase">
                {{ product.shortDescr }}
              </span>

              <div class="d-flex align-center w-100">
                <span
                  class="descr pl-0 d-flex align-center"
                  v-if="product.description"
                >
                  {{ product.description }}</span
                >
                <span
                  v-if="
                    product.productInfos?.TIPOLOGIA != 'Sfuso' &&
                      product.priceUmDisplay &&
                      product.weightUnitDisplay
                  "
                  class="weight-unit"
                >
                  {{ $n(product.priceUmDisplay, "currency") }}/{{
                    product.weightUnitDisplay
                  }}
                </span>
                <span
                  v-else-if="product.productInfos?.TIPOLOGIA == 'Sfuso'"
                  class="weight-unit descr"
                >
                  <!-- <span v-else class="weight-unit descr"> -->
                  {{ minQuantity }} {{ unit }}
                </span>

                <div
                  class="option-wrapper ml-auto"
                  v-for="selectOption in selectOptionsArray"
                  :key="selectOption.name"
                  @click.stop.prevent
                  @mousedown.stop
                >
                  <v-select
                    class="options-wrapper"
                    outlined
                    dense
                    depressed
                    single-line
                    hide-details
                    v-model="selectedOptions[selectOption.parameterName]"
                    :items="selectOption.options"
                    :label="selectOption.name"
                    item-text="key"
                    item-value="value"
                    @change="optionChanged"
                  />
                </div>

                <div
                  class="option-wrapper package_num"
                  v-if="product.productInfos?.SPLIT_ON_PACKAGE === 'true'"
                  @click.stop.prevent
                  @mousedown.stop
                >
                  <v-select
                    class="options-wrapper package_num"
                    outlined
                    dense
                    hide-details
                    background-color="white"
                    v-model="packageNum"
                    :items="packagesNum"
                    :label="$t('product.splitOnPackage')"
                  />
                </div>

                <!-- <SelectedOptionsMenu
                  v-if="
                    quantity > 0 || (selectedOptions && selectedOptions.name)
                  "
                  :product="product"
                  :selectOptionsArray="selectOptionsArray"
                  :selectedOptionSelected="selectedOptions"
                  @onOptionChanged="onOptionChanged"
                  @setSelectOptions="setSelectOptions"
                />-->
              </div>
            </div>

            <!-- <span class="product-classes-wrapper">
              <ProductClass
                :pClass="pClass"
                v-for="pClass in promoProductClasses"
                :key="pClass.productClassid"
              />
            </span> -->
            <!-- <span
              class="option d-flex align-center justify-space-between line-height-1 mt-2"
              style="white-space: initial;text-align: left;"
              v-for="(value, label) in selectedOptions"
              :key="label"
            >
              <div>
                <i>
                  Preferenza: <b> {{ value }}</b></i
                >
              </div>
            </span> -->
          </div>
        </div>

        <!-- <div
          v-if="selectedOptions.customWeight"
          class="cart-item-info"
          @click.stop.prevent="openOptionsModal(product.selectOptions)"
        >
          <em>Grammatura: {{ selectedOptions.customWeight }}gr</em>
        </div> -->
        <!-- v-if="product.priceDisplay && product.available > 0" -->
      </div>
    </div>
    <!-- <div class="px-3 fidelity-promo-label">
      <div v-if="promoFidelity && isAuthenticated">
        <v-icon size="18" color="#158655" class="pr-1">$fidelity</v-icon>
        {{ $t("product.promoWithFidelity") }}
      </div>
    </div> -->
    <div class="actions mt-auto" @click.stop.prevent>
      <div v-if="isAuthenticated && product.warehousePromo">
        <div
          v-if="showPromoBubble"
          class="promo_bubble"
          :class="[{ 'promo-fidelity-bg': promoFidelity }, promoClass]"
          v-html="product.warehousePromo.view.bubble"
        ></div>
        <div
          v-else-if="product.warehousePromo.warehousePromoTypeId != 5"
          class="special-price"
        >
          <img src="/img_layout/special-price.png" alt="Prezzo Speciale" />
        </div>
      </div>
      <div v-else-if="!isAuthenticated">
        <div v-if="product.warehousePromo" class="special-price-anon">
          <span>{{ $t("product.promoAnon") }}</span>
        </div>
      </div>
      <div
        class="promo-wrapper-product-card"
        :id="`promo-wrapper-${product.productId}`"
      >
        <div
          v-if="product.newProduct && !product.warehousePromo && !isHighlighted"
          class="new new-product text-uppercase white--text font-weight-semibold"
        >
          <div><v-icon color="white">$sparkle</v-icon></div>
          {{ $t("filter.new") }}
        </div>
        <!-- <ProductPromo
          v-if="product.warehousePromo"
          :warehousePromo="product.warehousePromo"
        />
        <template
          v-else-if="
            product.userGiftCertificates &&
              product.userGiftCertificates.length > 0
          "
        >
          <product-gift-certificate
            v-for="userGiftCertificate in product.userGiftCertificates"
            :key="userGiftCertificate.giftCertificate.giftCertificateId"
            :giftCertificate="userGiftCertificate.giftCertificate"
            :containerRef="$refs.productCard"
            @add="giftAdded"
            :wrapperId="`promo-wrapper-${product.productId}`"
          />
        </template> -->
      </div>

      <ProductPrice
        v-if="product.priceDisplay || product?.warehousePromo?.view?.MPieces"
        :product="product"
        :showStandardPrice="showStandardPrice"
        :showStandardDisplay="showStandardDisplay"
      />
      <v-spacer />

      <product-lead-time
        v-if="product.productInfos && product.productInfos.LEAD_TIME"
        :time="product.productInfos.LEAD_TIME"
        :showTooltip="true"
      />
      <!-- <SelectedOptionsMenu
        v-if="quantity <= 0"
        :product="product"
        :selectOptionsArray="selectOptionsArray"
        :size="'medium'"
        @onOptionChanged="onOptionChanged"
        @setSelectOptions="setSelectOptions"
      /> -->
      <DayLock
        v-if="product.dayLock"
        :days="product.dayLock"
        :showTooltip="true"
      />
      <LockCutoff
        v-if="product.productInfos && product.productInfos.PRODUCT_LOCK_CUTOFF"
        :hours="product.productInfos.PRODUCT_LOCK_CUTOFF"
      />
      <ProductQty
        v-if="product.available > 0"
        :key="key"
        :selectedOptions="selectedOptions"
        :product="product"
        :item="item"
        :packageNum="packageNum"
      />
      <div v-else class="product-not-available">
        <span
          v-if="
            product.dayLock ||
              (product.productInfos && product.productInfos.PRODUCT_LOCK_CUTOFF)
          "
          >{{ $t("product.dayLock") }}</span
        >
        <span v-else>{{ $t("product.notAvailable") }}</span>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
// .not-percent {
//   background: none !important;
// }

.highlited-product {
  border: 1px solid var(--v-primary-base) !important;
}
.product-highlighted-container {
  width: 64px;
  height: 64px;
  background-color: var(--v-primary-base);
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0px;
  border-radius: 9px 0px 6px 0px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  .highlighted-box-text {
    transform: rotate(-45deg);
    font-size: 12px;
    font-weight: 600;
  }
}
.options-wrapper .v-select__slot label {
  font-size: 12px !important;
}

.option-wrapper {
  max-width: 170px;
  div {
    border-radius: 4px;
    .v-input__slot {
      .v-select__slot {
        .v-select__selection {
          font-size: 12px;
        }
        .v-input__append-inner {
          i {
            font-size: 18px;
          }
        }
      }
    }
  }
}
.package_num {
  max-width: 64px;
  margin-left: 8px;
}
.promo-expire-date {
  color: #e11c24;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: 4px;
  left: 8px;
}
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .promo-expire-date {
    left: 70px;
  }
}

.fidelity-promo-label {
  color: $fidelity;
  font-size: 11px;
  font-weight: 600;
  min-height: 19px;
}
.product-not-available {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 0px 4px;
}

.new {
  background-color: #85cc34;
  width: 64px;
  height: 64px;
  position: absolute;
  top: -5px;
  left: -5px;
  border-radius: 9px 0px 6px 0px;
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.promo_bubble.mixmatch_sconto {
  strong {
    margin-top: 4px;
    line-height: 1rem;
  }
}

.promo_bubble {
  padding: 8px;
  position: relative !important;
  left: -7px !important;
  bottom: 0px !important;
  height: 64px;
  width: 64px;
  background: #e11c24 0% 0% no-repeat padding-box;
  border-radius: 0px 9px 0px 6px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  align-content: center !important;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  color: white;
  .promo-mxn {
    color: #e11d23;
    font-size: 24px;
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke: 3px white;
    paint-order: stroke fill;
    font-family: system-ui;
    letter-spacing: -1px;
    position: relative;
    z-index: 10;

    .var-m,
    .var-n {
      position: relative;
      z-index: 10;
    }

    .op-sign {
      position: relative;
      top: -3px;
      font-size: 16px;
      z-index: 1;
    }
  }
  p {
    text-transform: uppercase;
    font-size: 12px;
    margin: 0;
    padding: 0;
    font-weight: 600;
  }
  strong {
    font-size: 32px;
    font-weight: 600 !important;
    line-height: 2.2rem;
    .small {
      font-size: 18px;
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .promo_bubble {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    border-radius: 9px 0px 6px 0px !important;
  }
}

.product-card {
  .promo-fidelity-bg {
    background-color: #158655 !important;
  }
  .promo_mxn {
    background-color: #39a936 !important;
  }
  .promo_2x1 {
    background-color: #fddc2d !important;
  }
  .promo_sotto {
    background: none !important;
  }
  .promo_imbattibili {
    background: none !important;
    .imbattibili {
      height: 64px;
      img {
        border-top-left-radius: 10px;
      }
    }
  }
  .promo_sotto {
    background: none !important;
    .sottocosto {
      height: 64px;
      img {
        border-top-left-radius: 10px;
      }
    }
  }
  .promo-fidelity-text {
    color: #158655 !important;
  }
  // max-width: 255px;
  min-width: 255px !important;
  min-height: 387px;
  border-radius: 10px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029 !important;
  -webkit-box-shadow: 0px 3px 6px #00000029 !important;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-height: 225px;
  }

  .product-img {
    width: 170px;
    height: 170px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin: 0px 8px 0px 4px;
      width: 100px;
      height: 100px;
    }
    @media (max-width: 375px) {
      margin: 0px;
    }
  }
  .pulse-enter,
  .pulse-leave {
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
  }
  .actions {
    height: 66px;
    .v-menu__content {
      border-radius: $border-radius-root !important;
    }
    .selectOptions {
      margin: 4px;
    }
    .special-price-anon {
      padding: 8px;
      background: #e11c24 0 0 no-repeat padding-box;
      display: flex !important;
      align-items: center;
      justify-items: center;
      width: 64px;
      height: 64px;
      position: absolute;
      z-index: 3;
      left: 0;
      top: 0px;
      border-radius: 10px 0 10px 0 !important;
      // @media #{map-get($display-breakpoints, "xs-only")} {
      //   top: auto;
      //   bottom: 0;
      //   border-radius: 0 10px 0 10px !important;
      // }
      span {
        transform: rotate(-45deg);
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        position: relative;
        top: 0px;
        left: -7px;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    .actions {
      height: 58px;
    }
  }

  .selectOptionsMenu {
    z-index: 12;
  }
  .iva {
    font-size: 12px;
    white-space: nowrap;
  }
  .price-wraper {
    min-height: 60px;
  }
  .top {
    position: absolute;
    top: 4px;
    right: 4px;
    width: fit-content;
  }

  .actions {
    border-radius: 0px 0px 8px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 60px;
    margin-top: 6px;
    padding: 0px 6px;
  }

  .product-classes-wrapper {
    display: flex;
    margin-top: 5px;
    .product-class .product-class-icon {
      max-width: 25px;
    }
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }
  &.has-promo {
    .product-classes-wrapper {
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        top: 55px;
      }
    }
  }
}
.product-card {
  transition: border-color 0.3s ease;
  .short_descr {
    min-height: 22px;
  }
  &:hover {
    border: 1px solid $primary;
  }
}
</style>
<script>
import ProductAcqLimit from "./ProductAcqLimit.vue";
import ProductPrice from "./ProductPrice.vue";
import ProductQty from "./ProductQty.vue";
// import ProductManualInput from "./ProductManualInput.vue";
// import ProductClass from "./ProductClass.vue";
// import ProductInfo from "./ProductInfo.vue";
// import LeadTime from "./ProductLeadTime.vue";
import LockCutoff from "./ProductLockCutoff.vue";
import DayLock from "./ProductDayLock.vue";
// import AxBCutoff from "./ProductAxBCutoff.vue";
// import ProductGiftCertificate from "@/components/gift/ProductGiftCertificate.vue";
// import SelectedOptionsMenu from "@/components/product/SelectedOptionsMenu.vue";

import productMixin from "~/mixins/product";

import { mapState, mapGetters } from "vuex";

import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
// import ProductPromo from "./ProductPromo.vue";
import ProductLeadTime from "./ProductLeadTime.vue";
import cartService from "~/service/cartService";
import { mapActions } from "vuex";

export default {
  name: "ProductCard",
  props: {
    product: { type: Object, required: true },
    position: { type: Number, default: undefined }
  },
  components: {
    // ProductInfo,
    // ProductClass,
    ProductAcqLimit,
    ProductPrice,
    // ProductGiftCertificate,
    ProductQty,
    // ProductManualInput,
    // SelectedOptionsMenu,
    // LeadTime,
    LockCutoff,
    // AxBCutoff,
    DayLock,
    // ProductPromo,
    ProductLeadTime
  },
  mixins: [productMixin],
  data() {
    return {
      selectOptionsArray: [],
      selectedOptions: {},
      // selectablePromo: {},
      componentKey: 0,
      key: 0,
      // promoFidelity: false,
      packagesNum: ["1", "2", "3", "4", "5"],
      isHighlighted: false
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    showPromoBubble() {
      return (
        this.product.warehousePromo &&
        this.product.warehousePromo.view &&
        this.product.warehousePromo.view.bubble &&
        this.product.warehousePromo.warehousePromoTypeId &&
        [1, 6].includes(this.product.warehousePromo.warehousePromoTypeId)
      );
    },
    promoFidelity() {
      let cssClass = get(this.product, "warehousePromo.view.cssClass");
      return cssClass?.includes("promo_fidelity");
    },
    hasPercentage() {
      let cssClass = get(this.product, "warehousePromo.view.hasPercentage");
      return cssClass === "true";
    },
    promoPercent() {
      let cssClass = get(this.product, "warehousePromo.view.cssClass");
      return cssClass?.includes("promo_percent");
    },
    showStandardDisplay() {
      let result =
        this.product.warehousePromo &&
        this.product.warehousePromo.view &&
        this.product.warehousePromo.view.MPieces != null;
      if (result != true) {
        result = false;
      }
      return result;
    },
    showStandardPrice() {
      let result =
        (this.isAuthenticated &&
          this.product &&
          this.product.priceStandardDisplay &&
          this.product.priceStandardDisplay > 0.01 &&
          this.product.warehousePromo &&
          this.product.warehousePromo.warehousePromoTypeId &&
          this.product.warehousePromo.warehousePromoTypeId != 1 &&
          this.product.warehousePromo.warehousePromoTypeId != 2 &&
          this.product.warehousePromo.warehousePromoTypeId != 5 &&
          this.product.warehousePromo.warehousePromoTypeId != 6) ||
        (this.isAuthenticated &&
          this.product &&
          this.product.priceStandardDisplay &&
          this.product.priceStandardDisplay > 0.01 &&
          this.product.warehousePromo &&
          this.product.warehousePromo.warehousePromoTypeId &&
          this.product.warehousePromo.warehousePromoTypeId == 6 &&
          this.product.warehousePromo.view &&
          this.product.warehousePromo.view.hasPercentage === "true") ||
        (this.isAuthenticated &&
          this.product &&
          this.product.priceStandardDisplay &&
          this.product.priceStandardDisplay > 0.01 &&
          this.product.warehousePromo &&
          this.product.warehousePromo.warehousePromoTypeId == 6 &&
          this.product.warehousePromo.view &&
          this.product.warehousePromo.view.hasPercentage === undefined &&
          this.product.warehousePromo.view.MPieces === undefined &&
          this.product.warehousePromo.view.percentagePrice != undefined);
      if (result !== true) {
        result = false;
      }
      return result;
    },
    packageNum: {
      get() {
        return get(this.item, "cartItemInfo.package_num", "1");
      },
      set(value) {
        this.setCartItemInfo({
          items: this.item,
          name: "package_num",
          value: "" + value
        });
      }
    },
    promoProductClasses() {
      return this.product.productClasses.filter(
        pClass => pClass.productClassGroupId != 10002
      );
    },
    vendor() {
      return this.product.productClasses.find(
        element => element.productClassGroupId == 10002
      );
    },
    item() {
      this.key;
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item;
    },
    promoClass() {
      let productClasses = [];
      let cssClass = get(this.product, "warehousePromo.view.cssClass");
      if (cssClass) {
        productClasses.push("has-promo");
        productClasses.push(cssClass);
      }
      if (this.product.newProduct) {
        productClasses.push("new-product");
      }

      if (this.isHighlighted) {
        productClasses.push("highlited-product");
      }
      // if (this.product.edge) {
      //   productClasses.push(this.product.badge.cssClass);
      // }
      return productClasses;
    },
    minQuantity() {
      return cartService.plus(this.product);
    }

    // getItemUseWpId() {
    //   let useWpId = null;
    //   if (this.getItem) {
    //     useWpId = get(this.getItem, "cartItemInfo.use_wpid");
    //   }
    //   return useWpId;
    // }
  },
  methods: {
    ...mapActions({
      setCartItemInfo: "cart/setCartItemInfo"
    }),
    giftAdded() {
      this.plus();
    },
    onOptionChanged(args) {
      let selectOption = args.selectOption;
      let option = args.option;
      this.key++;
      this.selectedOptions[selectOption.parameterName] = option.key;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    },
    handleClick() {
      this.$emit("selectProduct");
    }
  },
  mounted() {
    if (this.product.selectOptions.length > 0) {
      this.selectOptionsArray = cloneDeep(this.product.selectOptions);
    }
    // let cssClass = get(this.product, "warehousePromo.view.cssClass");
    // this.promoFidelity = cssClass?.includes("promo_fidelity");

    this.isHighlighted = this.product.productClasses.some(
      productClass => productClass.productClassId === 10000 // Prodotto in evidenza
    );
    // if (this.product?.warehousePromo?.selectable) {
    //   this.selectOptionsArray.push({
    //     name: "Prodotto in scadenza",
    //     parameterName: "use_wpid",
    //     options: [
    //       {
    //         key: undefined,
    //         value: this.$t("product.selectablePromo.fastConsume.promoYes")
    //       },
    //       {
    //         key: this.product.warehousePromo.warehousePromoId,
    //         value: this.$t("product.selectablePromo.fastConsume.promoNo")
    //       }
    //     ]
    //   });
    // }
  }
};
</script>
